<template>
    <div class="shopping-cart">
        <table>
            <tr>
                <th>Image</th>
                <th>Item</th>
                <th>Description</th>
                <th>Price</th>
                <th>&nbsp;</th>
            </tr>
            <tr
            v-for="item in $store.state.cartItems"
            :key="item.id"
            >
                <td><img class="cart-image" :src="$store.state.URL+'media/'+item.image" alt="cart picture"></td>
                <td>{{item.item}}</td>
                <td>{{item.description}}</td>
                <td>${{item.price}}</td>
                <td><i @click="$store.commit('removeItem',item.id)" class="fas fa-times-circle delete-btn"></i></td>
            </tr>
            
        </table>
       
    </div>
</template>

<script>
export default {
    
}
</script>
<style lang="scss" scoped>
    table, th, td {
        border-spacing: 0;
        padding:0.5rem;
        text-align: center;
        vertical-align: middle;
        border-collapse: collapse;
        background:rgb(255, 255, 255);
        color:rgb(37, 37, 37);
    }

    .delete-btn{
        color:rgb(196, 34, 34);
    }
        
    th{
        font-weight: 600;
        background:rgb(216, 216, 216);
    }
    td{
        font-weight: 400;
        border-bottom: 1px solid rgba(0, 0, 0, 0.288);
    }
    table{
        width:100%;
        height:fit-content;
    }
    .cart-image{
        width:2rem;
        height:2rem;
    }
</style>