<template>
<div class="video-container" style="position:fixed;">
 
     <video @play="videoLoadHandler()" style="
    position:relative;
    width:100vw;
    margin-top:2.75rem;
    height:100vh;
    object-fit:cover;
    " autoplay muted loop>
      <source  :src="$store.state.URL+'media/'+'Moving-Waters-2017-for-Tuba-Marimba-Saxophone-and-Violin1.mp4'" type="video/mp4">
          Your browser is not supported!
    </video>
    <div v-if="$store.state.loaded" class="hero-message fadein">
    <div  class="relative-wrapper">
        <h2>Composer, pianist, audio engineer</h2>
        <h1>Josiah David Sprankle</h1>
        <br>
        <router-link to="/info"><base-button class="btn">Discover More</base-button></router-link>
    </div>
  </div>

    </div>
</template>

<script>
import BaseButton from '../components/ui/BaseButton.vue'
export default {
  components: { BaseButton },
  data(){
    return{
      $store:this.$store,
       videoLoadHandler(){
        console.log("foo")
            setTimeout(()=>{
              this.$store.state.loaded=true;
            },1000)
          }
    }
  },
 
}
</script>

<style lang="scss" scoped>

a{
  text-decoration: none;
  
}

.btn{
  display: block;
  margin: auto;
  background:var(--base-blue);
  color: white;
}
.fadein{
  animation:fadein 1s backwards;
}

.video-container{
  width:100%;
  height: 100%;
  top:0;
}

</style>

