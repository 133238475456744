<template>
  <div style="width:100vw;position:relative; scroll-behavior:smooth;">
 
    
     <dark-card id="home" style="width:100%;margin-top:-1.3rem;">
  <h2 class="heading-primary" style="font-weight:300;">Discover new music.</h2>
  </dark-card>
  <br>
  <white-card  class="gradient">
    <h3 class="kaushan" style="font-weight:900; font-size:2rem;">JDS Music</h3>
    <br>
    <p style="text-align:center; font-size:1.3rem;">Custom music composition and audio engineering for TV, Film, and Podcast. New scores. Sheet music.
    </p>
    <br>
    <div class="waveform-icon">
      <wave-form></wave-form>
    </div>
    <br>
    <hr>
    <dark-card style="width:100%;">
      <br>
      <p class="description">Thank you for stopping by my website. My name is Josiah Sprankle, and my passion is sound. Beautiful sounds, interesting
        sounds, and musical sounds. I love crafting audio recordings to perfection, creating new music, and sharing it with the world
        via sheet music notation.
      </p>
      <br>
      <p class="description">
        In this site, you will find <strong>sheet music PDF files</strong> and <strong>mp3 recordings</strong> available for download, as well as a complete inventory of my portfolio.
      </p>
      <br><br>
      <router-link :to="{path:'/info',hash:'#audio-services'}" style="text-decoration:none;color:rgb(26, 26, 26);"><base-button>What I Offer </base-button></router-link>
      <br><br>
      </dark-card>

      
    </white-card>
    
    <clip-path-cover>

      <div style="margin-top:5rem;" id="audio-services">
               <white-card style="width:calc(100% - 4rem);">
  <h2 class="heading-primary" style="font-weight:300;">Services</h2>
  </white-card>
        
        <div class="card-grid">
          <advert-card
            title="Music Composition"
            imageName="music-composition.svg"
            descLong="I will compose music for your podcast, film, TV show, or live performing ensemble. Having your own custom music can enhance the quality of your project and add a unique personal touch."
            descShort="Music that fits your vision."
            link="music-composition"
          ></advert-card>
          <advert-card
            title="Audio Engineering"
            imageName="music-speaker-box.svg"
            descShort="Recorded and mixed with excellence."
            descLong="I offer location and studio recording sessions for a great price. Once your project is recorded, I also offer mixing, mastering, production, and
               sound design. Take your podcast, audiobook, or musical recording to the next level with professional audio engineering."
            link="audio-engineering"
          ></advert-card>
          <advert-card
            title="Piano Performance"
            imageName="piano-top-view.svg"
            descLong="Want a professionally recorded keyboard track, or just need a pianist for a gig? I can play in a variety of styles ranging from
              from classical music to rock and roll. I will play for your wedding, accompany for your audition, or be a session musician for your album."
            descShort="Professional quality playing."
            link="piano-performance"
          ></advert-card>
          <!-- <white-card style="overflow:hidden;">
            
              <h2 class="card-headers">Audio Engineering</h2>
              <img src="../assets/music-speaker-box.svg" alt="">
              <br>
              <h3>Recorded and mixed with excellence.</h3>
               <br>
               I offer location and studio recording sessions for a great price. Once your project is recorded, I also offer mixing, mastering, production, and
               sound design. Take your podcast, audiobook, or musical recording to the next level with professional audio engineering.
              <br><br><br>
              <span class="center-button">
                <router-link to="/audio-engineering"><base-button class="clr-button">See More</base-button></router-link>
                
                </span>
          </white-card>
          <white-card style="overflow:hidden;">
              <h2 class="card-headers">Piano Performance</h2>
              <img src="../assets/piano-top-view.svg" alt="">
              <br>
              <h3>Professional quality playing.</h3>
              <br>
              Want a professionally recorded keyboard track, or just need a pianist for a gig? I can play in a variety of styles ranging from
              from classical music to rock and roll. I will play for your wedding, accompany for your audition, or be a session musician for your album.

              
              <br><br><br>
              <span class="center-button">
                <router-link to="/piano-performance"><base-button class="clr-button">See More</base-button></router-link>
                
                </span>
          </white-card> -->
        </div>
      </div>
      </clip-path-cover>




      <about-me-container id="about-me">
          <white-card class="gradient">
            <h2 class="heading-primary" style="font-weight:300;">About Me</h2>
            <dark-card class="about-me-grid">
                <div class="profile-container">
                  <img :src="`${dir}profile.jpg`" alt="profile pic">
                  <br>
                  <a style="color:grey;text-align:center;display:block;" target="_blank" href="https://mariagracephoto.com/">Photo Credits to Maria Grace Photography</a>
                  <div class="icon-row">
                   <a target="_blank" href="https://www.facebook.com/josiahdavidsprankle">
                   <svg enable-background="new 0 0 24 24" viewBox="0 0 24 24" width="2.5rem" xmlns="http://www.w3.org/2000/svg"><path d="m21 0h-18c-1.655 0-3 1.345-3 3v18c0 1.654 1.345 3 3 3h18c1.654 0 3-1.346 3-3v-18c0-1.655-1.346-3-3-3z" fill="#3b5999"/><path d="m16.5 12v-3c0-.828.672-.75 1.5-.75h1.5v-3.75h-3c-2.486 0-4.5 2.014-4.5 4.5v3h-3v3.75h3v8.25h4.5v-8.25h2.25l1.5-3.75z" fill="#fff"/></svg></a> 
                   <a target="_blank" href="https://www.youtube.com/channel/UCWK-JG1rOxgZ43kSuMwcmjA"><svg width="2.5rem" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"> <g> <path style="fill:#F44336;" d="M490.24,113.92c-13.888-24.704-28.96-29.248-59.648-30.976C399.936,80.864,322.848,80,256.064,80
                    c-66.912,0-144.032,0.864-174.656,2.912c-30.624,1.76-45.728,6.272-59.744,31.008C7.36,138.592,0,181.088,0,255.904
                        C0,255.968,0,256,0,256c0,0.064,0,0.096,0,0.096v0.064c0,74.496,7.36,117.312,21.664,141.728
                        c14.016,24.704,29.088,29.184,59.712,31.264C112.032,430.944,189.152,432,256.064,432c66.784,0,143.872-1.056,174.56-2.816
                        c30.688-2.08,45.76-6.56,59.648-31.264C504.704,373.504,512,330.688,512,256.192c0,0,0-0.096,0-0.16c0,0,0-0.064,0-0.096
                        C512,181.088,504.704,138.592,490.24,113.92z"/>
                      <polygon style="fill:#FAFAFA;" points="192,352 192,160 352,256 	"/>
                    </g>
                    </svg>
                    </a>
                    <a target="_blank" href="https://soundcloud.com/josiah-david-sprankle">
<!-- Generator: Adobe Illustrator 18.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg width="2.5rem" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 112.196 112.196" style="enable-background:new 0 0 112.196 112.196;" xml:space="preserve">
<g>
	<circle style="fill:#FF6A22;" cx="56.098" cy="56.098" r="56.098"/>
	<g>
		<path style="fill:#F1F2F2;" d="M16.707,68.833c-0.027,0.182-0.157,0.312-0.326,0.312c-0.169,0-0.299-0.13-0.325-0.316
			l-0.593-4.438l0.593-4.512c0.025-0.188,0.155-0.318,0.325-0.318c0.168,0,0.298,0.13,0.326,0.318l0.706,4.517L16.707,68.833z
			 M19.728,71.529c-0.028,0.193-0.168,0.328-0.341,0.328c-0.168,0-0.315-0.135-0.338-0.328l-0.798-7.139l0.798-7.299
			c0.024-0.188,0.17-0.328,0.338-0.328c0.173,0,0.313,0.137,0.341,0.328l0.908,7.299L19.728,71.529z M22.988,72.738
			c-0.023,0.232-0.197,0.397-0.406,0.397c-0.216,0-0.387-0.165-0.408-0.397l-0.757-8.342l0.757-8.659
			c0.019-0.235,0.192-0.403,0.408-0.403c0.209,0,0.382,0.168,0.406,0.403l0.859,8.659L22.988,72.738z M26.28,73.003
			c-0.024,0.266-0.232,0.467-0.479,0.467c-0.253,0-0.46-0.201-0.481-0.467l-0.715-8.607l0.715-8.897
			c0.021-0.268,0.228-0.469,0.481-0.469c0.247,0,0.455,0.201,0.479,0.469l0.811,8.897L26.28,73.003z M29.59,73.072
			c-0.017,0.309-0.258,0.538-0.543,0.538c-0.294,0-0.527-0.229-0.547-0.538l-0.676-8.677l0.676-8.254
			c0.021-0.307,0.253-0.536,0.547-0.536c0.29,0,0.526,0.229,0.543,0.532l0.766,8.258L29.59,73.072z M32.932,73.077v-0.005
			c-0.02,0.342-0.291,0.612-0.617,0.612c-0.327,0-0.598-0.271-0.617-0.607l-0.631-8.677l0.631-13.429
			c0.019-0.342,0.29-0.612,0.617-0.612c0.327,0,0.598,0.271,0.617,0.607l0.715,13.434L32.932,73.077z M36.249,73.072v-0.005
			c-0.02,0.378-0.319,0.679-0.689,0.679c-0.363,0-0.663-0.301-0.681-0.679l-0.596-8.621l0.596-16.501
			c0.018-0.378,0.318-0.677,0.681-0.677c0.37,0,0.669,0.299,0.689,0.677l0.671,16.501L36.249,73.072z M39.69,72.939v-0.005
			c-0.015,0.421-0.346,0.748-0.753,0.748c-0.407,0-0.738-0.327-0.753-0.743l-0.553-8.533c0,0,0.553-17.876,0.553-17.881
			c0.016-0.416,0.346-0.749,0.753-0.749c0.407,0,0.739,0.333,0.753,0.749l0.625,17.881L39.69,72.939z M43.111,72.882
			c-0.016,0.458-0.375,0.817-0.822,0.817c-0.448,0-0.807-0.359-0.823-0.813l-0.516-8.482l0.516-18.478
			c0.016-0.46,0.375-0.818,0.823-0.818c0.447,0,0.807,0.358,0.822,0.818l0.573,18.478L43.111,72.882z M46.554,72.807v-0.005
			c-0.016,0.495-0.403,0.888-0.894,0.888c-0.484,0-0.879-0.393-0.888-0.883l-0.474-8.401L44.767,46.4
			c0.014-0.502,0.408-0.89,0.893-0.89c0.491,0,0.878,0.388,0.894,0.89l0.532,18.006L46.554,72.807z M50.025,72.757V72.75
			c-0.008,0.539-0.434,0.954-0.96,0.954c-0.53,0-0.95-0.415-0.966-0.947l-0.428-8.352L48.1,47.058
			c0.016-0.538,0.436-0.959,0.966-0.959c0.526,0,0.952,0.421,0.96,0.959l0.485,17.352L50.025,72.757z M53.566,71.876l-0.043,0.818
			c-0.005,0.285-0.125,0.543-0.308,0.729c-0.188,0.183-0.446,0.3-0.719,0.3c-0.318,0-0.598-0.146-0.79-0.376
			c-0.142-0.167-0.23-0.383-0.24-0.61c-0.003-0.01-0.003-0.023-0.003-0.037c0,0-0.395-8.286-0.395-8.3l0.39-20.443l0.005-0.196
			c0.003-0.358,0.193-0.677,0.477-0.86c0.159-0.106,0.347-0.168,0.557-0.168c0.206,0,0.401,0.064,0.564,0.177
			c0.277,0.185,0.458,0.496,0.463,0.852l0.434,20.648L53.566,71.876z M57.007,72.587v-0.01c-0.007,0.604-0.502,1.096-1.1,1.096
			c-0.6,0-1.094-0.492-1.1-1.091l-0.227-4.03l-0.223-4.138l0.45-22.406v-0.113c0.001-0.34,0.161-0.644,0.408-0.849
			c0.189-0.155,0.432-0.249,0.692-0.249c0.207,0,0.399,0.057,0.563,0.153c0.313,0.196,0.532,0.543,0.542,0.94l0.487,22.523
			L57.007,72.587z M86.74,73.695c0,0-27.683,0-27.712,0c-0.598-0.061-1.071-0.537-1.081-1.15V40.821
			c0.01-0.584,0.211-0.885,0.963-1.174c1.941-0.752,4.139-1.196,6.398-1.196c9.215,0,16.766,7.069,17.567,16.074
			c1.186-0.495,2.496-0.777,3.865-0.777c5.518,0,9.992,4.476,9.992,9.998C96.732,69.267,92.258,73.695,86.74,73.695L86.74,73.695z"
			/>
	</g>
</g>
</svg>
                  </a>
                  </div>


                   
               
                </div>
                <div id="info">
                  <h3>BACKGROUND</h3>
                      <p class="description">I primarily work in Kansas City, where I received a Bachelor of Music in Composition and Piano Performance at the 
                      University of Missouri, Kansas City. I am a classically-trained musician with a love for making things up on the spot. I have a desire
                      to constantly create something new, and polish my compositions to excellence. Although I love classical music, I also love to 
                      experiment with other genres. I have recorded rock, bluegrass, contemporary Christian music, and country, and I regularly produce music in Ableton Live.
                  

                  </p>
                  <h3>COMPOSITION</h3>
                  <p class="description">
                    On the composition end of things, I compose for Film/TV/Visual Media, classical ensembles, as well as do a bit of songwriting.
                    I have had the privilege of working student directors of short films, as well as directors of feature-length films. My classical
                    compositions have won awards such as the MTNA National Young Artist Awards and ALEA III.
                  </p>
                  <h3>PIANO</h3>
                  <p class="description">
                   I am an avid pianist and love performing, regardless of the genre. I perform in churches, concerts, weddings, you name it! My current job is the staff accompanist
                   at Lafayette High School, where I accompany all of the choirs and musicals. At UMKC, I was the winner of the concert-aria division for piano, where I 
                   performed the first movement of Frederic Chopin's Concerto No. 2 in F minor. 
                  </p>
                  <h3>AUDIO ENGINEERING</h3>
                  <p class="description">
                   At UMKC, I completed an audio internship where I recorded and mastered student recitals. Afterwards,
                   I put my skills to use and started a small audio engineering business. My selection of microphones includes a variety of
                   quality microphones from Shure, Sennheiser, Cascade, and others, and I can record everything from French horn to drumkit. My studio includes space for about 3-4 performers. I have an active Upwork page where I edit audio recordings
                   of speech, audiobooks, and podcasts for a reasonable price. Sound design is also included in my work. 
                  </p>
                </div>
                
                
            </dark-card>
          </white-card>
      </about-me-container>




  </div>
</template>

<script>
// import BaseCard from '../components/ui/BaseCard'
import WhiteCard from '../components/ui/WhiteCard'
import AdvertCard from '../components/ui/AdvertCard'
import DarkCard from '../components/ui/DarkCard'
import BaseButton from '../components/ui/BaseButton'
import ClipPathCover from '../components/layout/ClipPathCover'
import AboutMeContainer from '../components/layout/AboutMeContainer'
import WaveForm from '../assets/waveform.vue'

export default {
  components:{
    // BaseCard,
    AdvertCard,
    WhiteCard,
    DarkCard,
    BaseButton,
    ClipPathCover,
    AboutMeContainer,
    WaveForm
  },
  data(){
    return{
      dir:this.$store.state.URL+"media/",
      image:require("../assets/waveform.svg")
    }
  },
  mounted(){
    console.log(this.image);
  }
}
</script>

<style lang="scss" scoped>
h3{
  font-weight: 600;
}
.description{
  font-size:1.15rem !important;
}
</style>

