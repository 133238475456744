<template>
<teleport to="body">
    <div id="audio-player" class="music-player fadein">
        <div class="relative-wrapper">
            <div class="border"></div>
             <div class="container">
            <div @click="close" class="x-icon"><i class="fas fa-times-circle delete-btn"></i></div>
            <div class='image-container'>
                <img class="album-image fadeinslow" :src="dir+info.picture" alt="album cover">
            </div>
        
        <div class="side-right">
            <br><br><br>
            <h2 style="font-weight:600;">{{info.title}}</h2>
            <br>
            <p style="width:80%;margin:auto;color:grey;">{{info.description}}</p>
            <br>

            <button v-if="!$store.getters.addedIDs.includes(info.id)" @click="$store.commit('addItem',info)" class="btn">${{info.price}}</button>
            <button v-else class="btn">Added to cart!</button>

            <ul>
                <li 
                v-for="(song, index) in info.songs"
                :key="song"
                
                >
                <br>
                <p class="song-title">{{index+1}}. {{parseTitle(song)}}</p>
                <br>
                    <audio controls :src="dir+song"></audio>
                <br>
                </li>
                
            </ul>

        </div>
        
            </div>
            <div class="border"></div>
        </div>
    </div>
</teleport>
</template>

<script>
export default {
    emits:['close-player'],
    props:['info'],
    data(){
        return{
            dir:this.$store.state.URL+'media/',
        }
    },
    methods:{
        parseTitle(str){
            const regex1 = new RegExp('.+_')
            const regex2 = new RegExp('[.].+')
            const regex3 = new RegExp('-','g')
            let str1 =  str.replace(regex1,"")
            let str2 =  str1.replace(regex2,"")
            return str2.replace(regex3," ")

        },
        close(){
            document.getElementById('audio-player').classList.add('fadeout')
            setTimeout(()=>{
                this.$emit('close-player')
            },500)
        }
    }
}
</script>
<style lang="scss" scoped>
    .music-player{
        position: absolute;;
        top:0;
        left:0;
        height:100vh;
        width:100vw;
        color:rgb(233, 233, 233);
        overflow:hidden;
        z-index:10;

        .container{
            width:100vw;
            height:calc(100vh - 5rem);
            position:relative;
            display:grid;
            grid-template-columns: 50% 50%;
            align-items: center;
            background:rgb(0, 0, 0);
        }
         @media only screen and (max-width:600px){
        .container{
            grid-template-columns: 100%;
            grid-template-rows: 1px auto;
        }
        .album-image{
            margin-top:100%;
        }
    }
        .side-right{
            background-color: rgba(0, 0, 0, 0.808);
            position:relative;
            height:100%;
            overflow: scroll;
        }
    }

    .album-image{
        width:100%;
        object-fit:cover;
        display:block;
   
        
    }
    .image-container{
        position: relative;
        width:100%;
        height:100%;
        display: grid;
        align-content: center;
        justify-content: center;
    }

    audio{
        width:100%;
        height:1.5rem;
        border-radius: 0.1rem;
    }

    ul{
        overflow:scroll;
        margin:2rem;
        height:max-content;
        background:rgb(8, 8, 8);
        padding:1rem;
        border-radius:0.5rem;
    }

    .btn{
        margin:auto;
        display: block;
    }
    .x-icon{
        position: absolute;
        right:1rem;
        top:1rem;
        font-size: 1.75rem;
        color:red;
        z-index:1;
        
    }
    .border{
        width:100%;
        height:2.5rem;
        background:rgb(187, 187, 187);
        z-index:100;
    }
    li{
        list-style: none;
    }
    
    @keyframes fadein{
        0%{
            opacity: 0;
            transform: translateY(5rem);
        }
        100%{
            opacity: 1;
            transform: translateY(0);
        }
    }
 

   

    
   
</style>