<template>
  <div class="sheet-music">
     <white-card class="gradient">
       <h2 class="heading-primary" style="font-weight:300;">Sheet Music</h2>
       <br>
       <h3 class="heading-secondary">Find scores here.</h3>
       
       <br>
       <transition name="fade">
      <PDFViewer
      :score="selectedScore"
      v-if="PDFVisible"
      @close-pdf="
      PDFVisible=false"
      />
       </transition>
      <dark-card id="sheet-music-container">
         <div class="search-row">
           <div class="search-and-icon">
             <span class="search-icon"><i class="fas fa-search"></i></span>
            <input v-model="searchInput" @input="searchScores" id="search-input" placeholder="Search for scores..." type="text">
           </div>
           
            <select @change="searchScores" name="genre" id="genre">
              <option value="">Select Genre</option>
              <option value="Chamber Music">Chamber Music</option>
              <option value="Orchestra">Orchestra</option>
              <option value="Band">Band</option>
              <option value="Piano">Piano</option>
              <option value="Solo Instrument">Solo Instrument</option>
              <option value="Choir">Choir</option>
              <option value="Voice">Voice</option>
            </select>
            <select @change="searchScores" name="player-count" id="player-count">
              <option value="">Select Performers</option>
              <option value="==1">1</option>
              <option value="==2">2</option>
              <option value=">=3,<=5">3-5</option>
              <option value=">=5,<=10">5-10</option>
              <option value=">=10">10+</option>
            </select>
            <select @change="searchScores" name="duration" id="duration">
              <option value="">Select Duration</option>
              <option value="<=2">2 min. or fewer</option>
              <option value=">=2,<=3">2-3 min.</option>
              <option value=">=3,<=6">3-6 min.</option>
              <option value=">=6,<=10">6-10 min.</option>
              <option value=">=10,<=15">10-15 min.</option>
              <option value=">=15,<=30">15-30 min.</option>
            </select>

        </div>
        <br>
        <hr>
        <br>
        <div class="sheetmusic-grid">
          <score-element
          v-for="score in scores" v-show="score.visible" :key="score.id" :score="score" @show-score="showPDF(score.id)"></score-element>
        </div>
        
      </dark-card>
     </white-card>
  </div>
</template>

<script>
import WhiteCard from '../components/ui/WhiteCard'
import DarkCard from '../components/ui/DarkCard'
import PDFViewer from '../components/complex/PDFViewer'
import ScoreElement from '../components/complex/ScoreElement.vue'
export default {
  components:{
    DarkCard,
    WhiteCard,
    PDFViewer,
    ScoreElement
  },
  data(){
    return{
      PDFVisible:false,
      selectedId:1,
      selectedScore:"",
      searchInput:"",
    }
  },

  methods:{
    showPDF(id){
      let ind = this.scores.findIndex(score=>score.id==id)
      this.selectedScore = this.scores[ind]
      this.PDFVisible=true
    },
    searchScores(){


      let s = new RegExp(this.searchInput,'i')
      let genre = document.getElementById('genre').value
      console.log(genre)
      let playerCount = document.getElementById('player-count').value
      let duration = document.getElementById('duration').value

      this.scores.forEach(score=>{
        //sets all true by default
        score.visible=true

        if(this.searchInput){
            if(
            !score.instruments.join("").match(s)&&
            !score.title.match(s)&&
            !score.description.match(s)
            ){
            score.visible=false
          }
        }
        if(genre){
          if(score.genre!=genre){
            score.visible=false
          }
        }

        

         if(playerCount){
           let playerCountConditionsArr = playerCount.split(",")
           let count = score.instruments.length
           if(score.genre=='Orchestra'){
             count = 100
           }
           console.log(playerCount)
              playerCountConditionsArr.forEach(condition=>{
                if(!eval(count+""+condition)) score.visible=false
              })      
          }

             if(duration){
           let durationConditionsArr = duration.split(",")
              durationConditionsArr.forEach(condition=>{
                if(!eval(score.duration+""+condition)) score.visible=false
              })      
          }
   

        
       
      })
    }
  },
  computed:{
    scores(){
      return this.$store.state.scores
    }
  },
  mounted(){
    setTimeout(()=>{
      document.querySelector('#top-of-page').scrollIntoView({ behavior: 'smooth' });
    },100)
    
  }
}

</script>

<style lang="scss" scoped>
.sheet-music{
  margin-top:-2rem;
}
#search-input{
  font-size:1rem;
  padding:0.76rem;
  border:none;
  box-shadow:none;
  outline: transparent;
  transition:0.25s;
  margin-right:0.5rem;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  width:80%;
  padding-left: 2.5rem;

  &:focus{
    background:rgb(145, 164, 182);
  }
}
.search-and-icon{
  display:flex;
  align-items:center;
  flex:3;
  width:100%;
  min-width:200px;
}
.search-icon{
  background: var(--base-blue);
font-size: 1.5rem;
border-top-left-radius: 2rem;
border-bottom-left-radius: 2rem;
border-radius: 50%;
height: 2.75rem;
width: 2.75rem;
display: flex;
align-items: center;
justify-content: center;
margin-right: -1.5rem;
z-index: 1;
  
}
select{
  font-size:1rem;
  padding:0.69rem;
  border:none;
  appearance: none;
  border-left:1px solid rgba(128, 128, 128, 0.26);
  border:none;
  box-shadow:none;
  outline: transparent;
  color:rgb(230, 230, 230);
  background:rgb(46, 46, 46);
  text-align: center;
  flex:1;
  border-radius:0.2rem;
  cursor:pointer;
  transition:0.3s;
  &:hover{
    opacity: 0.6;
  }
  option{
    background:white;
    color:rgb(34, 34, 34);
  }
}
.search-row{
  display: flex;
  width:100%;
  flex-direction: row;
  align-items: center;
  height:fit-content;
  flex-wrap: wrap;
  gap:0.5rem;
  animation:fadein 0.3s backwards ease-in;;
  animation-delay: 0s;
}
.sheetmusic-grid{
  display:grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  width:100%;
  height:auto;
  column-gap: 0.5rem;
  row-gap:0.5rem;
   @media only screen and (max-width:900px){
        grid-template-columns:100%;
    }
  animation:fadein 0.3s backwards ease-in;;
  animation-delay:0s;
}
.heading-secondary{
  
  font-weight: 300;
}

#sheet-music-container{
  background: rgb(24, 24, 24);
}
    


  @keyframes fadein{
    0%{
      opacity:0;
    }
    100%{
      opacity:1;
    }
  }

</style>