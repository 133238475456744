<template>
    <div class="composition-root">
        <div class="carousel-container">
             <div style="width:75%;min-width:320px;height:50vh; margin:auto;overflow:hidden;">
                <carousel
                    :items='[
                    `<img style="object-fit:cover;width:100%;height:100%;" src="${dir}Off-Limits.jpg" alt="stock image">`,
                    `<img style="object-fit:cover;width:100%;height:100%;" src="${dir}chamber-music-header.png" alt="stock image">`,
                    `<img style="object-fit:cover;width:100%;height:100%;" src="${dir}orchestra-pic.jpg" alt="stock image">`,
                    `<img style="object-fit:cover;width:100%;height:100%;" src="${dir}composers-guild.jpg" alt="stock image">`,
                    `<img style="object-fit:cover;width:100%;height:100%;" src="${dir}worship-night.jpg" alt="stock image">`,
                    `<img style="object-fit:cover;width:100%;height:100%;" src="${dir}wind-quintet.jpg" alt="stock image">`,
                 
                    ]'
                ></carousel>
            </div>
                <div style="transform:translate(-50%,-150%);" class="hero-message">
                    <div class="relative-wrapper">
                        <h2>Real Music. Real people.</h2>
                        <h1>Music Composition</h1>
                    </div>
                </div>
        </div>
        <white-card style="width:100%;margin-top:-0.75rem;" class="gradient">
            <br>
            <h1 style="font-weight:300" class="heading-primary">Genres</h1>
            <h3 style="font-weight: 300;text-align:center;" class="heading-secondary">Here's what I write for.</h3>
            <br>
            <div class="cards">
            <base-card  style="width:100%;overflow:hidden;">
            <div class="giant-ball"> </div>
            <i class="far fa-lightbulb"></i>
                <h2 class="heading-secondary">Original Music</h2>
                <p class="description"> I will compose music for your podcast, film, TV show, or live performing ensemble. Obtaining royalties can be a difficult process, but having your own custom music can enhance the quality of your project and add a unique personal touch.</p>
            </base-card>

                <base-card style="width:100%;overflow:hidden;">
                <div class="giant-ball"> </div>
                    <i class="fas fa-video"></i> 
                    <h2 class="heading-secondary">Film and Media</h2>
                    <p class="description">I can either use sheet music or professional-grade VST's for your soundtrack.</p>
                </base-card>
                <base-card style="width:100%;overflow:hidden;">
                <div class="giant-ball"> </div>
                <i class="fas fa-music"></i>
                    <h2 class="heading-secondary"> Sheet Music &amp; Arranging</h2>
                <p class="description">I can arrange music, create sheet music of another composition by ear, or I can help you notate one of your ideas.</p>
                </base-card>
                <base-card style="width:100%;overflow:hidden;">
                <div class="giant-ball"> </div>
                <i class="fas fa-guitar"></i>
                    <h2 class="heading-secondary"> Classical Ensembles</h2>
                <p class="description">I can compose an original work for your string quartet, orchestra, high school band, or something completely different!</p>
                </base-card>
            </div>
            <br> <br> <br><br>
        </white-card>
        <div class="dark-section">
            <img :src="dir+'big-piano.jpg'" alt="">
            <br><br>
                <portfolio-card style="margin-top:1.5rem;"></portfolio-card>
            
            <div class="soundcloud-grid">
                <div>
                    <dark-card style="height:auto;width:100%;margin-bottom:-0.9rem;"><h2>MIDI Compositions</h2></dark-card>
                    <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1102588537&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                </div>
                <div>
                    <dark-card style="height:auto;width:100%;margin-bottom:-0.9rem;"><h2>Classical Pieces</h2></dark-card>
                    <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1198620217&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                </div>
                
            </div>
        </div>
                

        
    </div>
</template>

<script>
import Carousel from '../components/composition/Carousel.vue'
import BaseCard from '../components/ui/BaseCard.vue'
import DarkCard from '../components/ui/DarkCard.vue'
import WhiteCard from '../components/ui/WhiteCard.vue'
import PortfolioCard from '../components/ui/PortfolioCard.vue'

export default {
  components: { Carousel, WhiteCard, BaseCard, DarkCard, PortfolioCard },
  data(){

  },
  computed:{
      dir(){
          return this.$store.state.URL+'media/'
      }
  }
    
}
</script>

<style lang="scss" scoped>
.btn{
  background:rgb(5, 4, 75);
  color:rgb(226, 226, 226);
  font-size:1.2rem;
  text-decoration: none;
  display:block;
  padding:1rem;
  margin:auto;
 
}
.cards{
    display:grid;
    grid-template-columns: 45% 45%;
    column-gap: 4%;
    width:100%;
    row-gap:1.5rem;
    justify-content: center;
     @media only screen and (max-width:800px){
          grid-template-columns: 100%;
 
        }
    & p{
        z-index:2;
        position:relative;
    }
    & h2{
        font-size:1.8rem;
        text-align:center;
        position:relative;
        width:65%;
        margin:auto;
        margin-bottom:1rem;
        margin-top:0rem;
        padding-bottom: 1rem;
        border-bottom:1px solid rgba(128, 128, 128, 0.342);
    }
 
  
    i{
        color:rgb(52, 63, 221);
        background:rgb(255, 255, 255);
        height:4rem;
        width:4rem;
        padding:1rem;
        font-size:2rem;
        border-radius:50%;
        text-align:center;
        position:absolute;
        left:0.5rem;
        top:0.5rem;
        @media only screen and (max-width:800px){
            font-size:1.7rem;
            width:3.5rem;
            height:3.5rem;
 
        }


    }
    
}

  


.composition-root{
    margin-top:-2rem;
}
.carousel-container{
    background:black;
    width:100%;
    height:auto;
    box-shadow:0px 5px 10px rgba(0, 0, 0, 0.623);
    
}
.heading-secondary{
    text-align:left;
    margin:1rem;
}

.description{
        font-size:1rem !important;
}
.dark-section{
    min-height:100vh;
    height:auto;
    width:100vw;
    position:relative;
    background:grey;
    margin-top:-1rem;
    & img{
        position: absolute;;
        width:100%;
        height:100%;
        object-fit: cover;
        filter:grayscale(100%) blur(5px);
    }
}
.giant-ball{
    background:var(--base-blue-dark);
    width:100%;
    height:200%;
    border-radius:50%;
    position:absolute;
    bottom:2rem;
    left:0;

}


.samples-description{
    position:relative;
    padding-top:2rem;
    background:rgba(107, 107, 107, 0.568);
    padding-bottom:2rem;
}
h2{
    font-weight:500;
}
</style>