<template>
    <header>
        <div class="relative-wrapper navbar">     
            <!--It needs to be a little tricky, because if I am on the home page, I have to go to the spot with the given ID,
            but if I am on a different page, I have to go to the home page first. 
            -->
            <a v-if="$route.path=='/'" style="color:white; text-decoration:none;" href="#home">
            <div class="image-container">
                <img :src="`${dir}logo-1.png`" alt="">
            </div>
            </a>
            <router-link v-else  style="color:white; text-decoration:none;" to="/">
            <div class="image-container">
                <img :src="`${dir}logo-1.png`" alt="">
            </div>
            </router-link>
            
         
            <router-link class="link" :to="{path:'/info'}"><span class="hide-in-mobile">Info&nbsp;</span>
                 <ul>
                    <router-link class="link-light" :to="{path:'/info',hash:'#about-me'}"><li>About Me</li></router-link>
                </ul>
            <i class="fas fa-info-circle"></i></router-link>
            <router-link class="link" :to="{path:'/info',hash:'#audio-services'}"><span class="hide-in-mobile">Services&nbsp;
                </span><i class="fas fa-headphones"></i>
                <ul>
                    <router-link class="link-light" to="/music-composition"><li>Composition</li></router-link>
                     <router-link class="link-light" to="/audio-engineering"><li>Audio Engineering</li></router-link>
                     <router-link class="link-light" to="/piano-performance"><li>Piano Performance</li></router-link>
                </ul>
            </router-link>
            <router-link class="link" to="/sheet-music"><span class="hide-in-mobile">Sheet Music&nbsp;</span><i class="fas fa-book-open"></i></router-link>
            <router-link class="link" to='/listen'><span class="hide-in-mobile">Listen&nbsp;</span><i class="fas fa-music"></i></router-link>
            <router-link class="link" to='/contact'><span class="hide-in-mobile">Contact&nbsp;</span><i class="fas fa-envelope-square"></i></router-link>
            <router-link class="link" to="/cart"><span class="hide-in-mobile">Cart&nbsp;</span><i class="fas fa-shopping-cart"></i>
                <span v-if="$store.getters.quantity" class="cart-tally">{{$store.getters.quantity}}</span>
            </router-link>
            
        </div>
    </header>
    
</template>

<script>

export default {
    data(){
        return{
            dir:this.$store.state.URL+"media/"
        }
    },
    computed:{
        homelink(){
            if(this.$route=='/'){
                return '#home'
            }else{
                return '/'
            }
        }
    }
}
</script>

<style lang="scss" scoped>

header{
    height:5.5rem;
    background:linear-gradient(rgb(15, 15, 15) 0%,rgb(39, 39, 39) 50%, rgb(15, 15, 15) 100%);
    position:fixed;
    top:0;
    left:0;
    width:100%;
    padding:0.5rem;
    box-shadow:0px 3px 10px rgba(0, 0, 0, 0.795);
    color:rgb(230, 230, 230);
    z-index:2;

    .navbar{
        
        display:flex;
        flex-direction:row;
        height:100%;
        width:calc(100% - 7rem);
        max-width:800px;
        justify-content: space-around;
        align-items:center;
        position:absolute;
        right:0.5rem;
        top:0;



        .link{ 
            font-size:1.1rem;
            display:inline-block;
            color:rgb(214, 214, 214);
            text-decoration: none;
            transition:0.3s;
            position:relative;
            font-weight:400;
            padding-top:2rem;
            padding-bottom:2rem;

            ul{
                position: absolute;
                top:0rem;
                margin-top:4.5rem;
                left:0;
                height: max-content;
                width: max-content;
                background-color:rgba(255, 255, 255, 0.918);
                box-shadow:3px 3px 5px rgba(0, 0, 0, 0.438);
                opacity:0;
                transform: rotateY(90deg);
                transition:0.5s;
                list-style: none;
                visibility: hidden;
                
                .link-light{
                    text-decoration: none;
                    color:rgba(0, 0, 0, 0.904);
                }

                li{
                    border-top:1px solid rgba(0, 0, 0, 0.11);
                    padding:0.5rem;
                    transition:0.15s;
                    &:hover{
                        background:rgba(211, 211, 211, 0.918);
                        color:var(--base-blue-light)
                    }
                }
            }
            &::after{
                height:2px;
                width:0%;
                content:"";
                background:var(--base-blue-light);
                position:absolute;
                bottom:1.5rem;
                left:0;
                transition:0.3s;
            }
            &:hover{
                color:var(--base-blue-light);
                & ul{
                    opacity:1;
                    transform: rotateY(0deg);
                    visibility: visible;
                }
                &::after{
                    width:100%;
                }
            }
        }
        .image-container{
            height:4rem;
            width:7rem;
            transition:0.3s;
            position:fixed;
            left:0;
            text-align:center;
            top:0.5rem;
            

            &:hover,&:active{
                opacity:0.7;
            }

            img{
                height:3rem;
                width:7rem;
                margin:auto;
                object-fit:cover;
                display:block;
                margin-top:0.75rem;
                margin-left:0.5rem;

            }

            h1{
                font-size:0.85rem;
            }

        }

    }

    .cart-tally{
        position: absolute;
        color: white;
        right: -1rem;
        background: var(--base-blue);
        height: 1.2rem;
        width: 1.2rem;
        text-align: center;
        border-radius: 50%;
        bottom: 50%;
        font-size: 1rem;
    }

    @media screen and (max-width:900px) {
        .hide-in-mobile{
            display:none;
        }
        .image-container{
            position:relative;
        }
    }
    @media screen and (max-width:400px) {
       
    }
}

</style>