<template>
  <div class="listen">
    <div style="height:6rem;"></div>
    <div class="listen-grid">

      <div class="main">
        <div id="albums">
         <white-card  class="gradient" style="width:100%;height:auto;margin-top:-0.5rem;"><h2 style="height:fit-content;font-weight:300;" class="heading-primary">Albums</h2></white-card>
          <ul class="audio-player-row">
          <li
          style="display:inline-block;margin-right:1rem;"
          v-for="album in albums"
          :key="album.title"
          >
            <img @click="togglePlayer(album.title)" class="album-image" :src="dir+album.thumbnail" alt="album image">
          </li>
           
            <transition name="fade">
            <AudioPlayer
            @close-player="audioPlayerVisible=false"
            v-if="audioPlayerVisible"
            :info="currentAlbum"
            />
            </transition>
            
          </ul>
          </div>
          <div id="film-scores">
          <white-card  class="gradient" style="width:100%;height:auto;"><h2 style="height:fit-content;font-weight:300;" class="heading-primary">Film Scores</h2></white-card>
          <div class="video-grid">
             
              <div @click="makeSelectedVideo(video.title)" v-for="video in internalVideos" :key="video.title" class="video-container internal">
                <h3 class="media-description">{{video.title}}</h3>
                 <video autoplay v-if="selectedVideo == video.title" class="uploaded-video" controls style="width:100%; height:100%;" :src="dir+video.src"></video>
                 <div v-if="selectedVideo !== video.title" class="placeholder-img-container">
                    <img class="placeholder-img" 
                    :src='thumbnailURL(video.thumbnail)'
                    alt="">
                </div>
              </div>
             
              <div @click="makeSelectedVideo(video.title)" v-for="video in externalVideos" :key="video.title" class="video-container external">
                <h3 class="media-description">{{video.title}}</h3>
                <iframe @load="playVideo($event)" v-if="selectedVideo == video.title" :id="formatTitle(video.title)" class="youtube-video" width="100%" height="100%" :src="video.src+'?autoplay=1'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div v-if="selectedVideo !== video.title" class="placeholder-img-container">
                    <img  class="placeholder-img" 
                    :src='`https://img.youtube.com/vi/${video.src.replace(/.+\/.+\//,"")}/0.jpg`'
                    alt="">
                </div>
            
              </div>
             
          </div>
          </div>


          <div class="soundcloud-grid">
            <div>
              <white-card id="music-production" class="gradient" style="width:100%;height:auto;"><h2 style="height:fit-content;font-weight:300;" class="heading-primary">Music Production</h2></white-card>
              <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1102588537&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/joeydudejosiah" title="Josiah Sprankle (MIDI Compositions)" target="_blank" style="color: #cccccc; text-decoration: none;">Josiah Sprankle (MIDI Compositions)</a> · <a target="_blank" href="https://soundcloud.com/joeydudejosiah/sets/jds-music-production" title="JDS Music Production"  style="color: #cccccc; text-decoration: none;">JDS Music Production</a></div>
            </div>
            <div>
              <white-card id="mixing-and-mastering" class="gradient" style="width:100%;height:auto;"><h2 style="height:fit-content;font-weight:300;" class="heading-primary">Mixing &amp; Mastering</h2></white-card>
               <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1096588258&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/user-134436293" title="JDS Music (mixing &amp; mastering)" target="_blank" style="color: #cccccc; text-decoration: none;">JDS Music (mixing &amp; mastering)</a> · <a target="_blank" href="https://soundcloud.com/user-134436293/sets/jds-music-mixes" title="JDS Music Mixes" style="color: #cccccc; text-decoration: none;">JDS Music Mixes</a></div>
            </div>
          </div>
          
          
      </div>
    </div>
  </div>
</template>

<script>
import WhiteCard from '../components/ui/WhiteCard'
import AudioPlayer from '../components/complex/AudioPlayer'
export default {
  components:{
    WhiteCard,
    AudioPlayer
  },
  data(){
    return{
      dir:this.$store.state.URL+'media/',
      audioPlayerVisible:false,
      ind:0,
      internalVideos:[
        {title:"Wedding Screeners",src:'I-Miss-Mom-So-Much.mp4',thumbnail:"wedding-screeners.png"}
      ],
      externalVideos:[
        {title:"Venom Trailer",src:"https://www.youtube.com/embed/tTVBXMjkEDQ"},
        {title:"No Country for Old Men",src:"https://www.youtube.com/embed/KO7H0ltkusk"},
        {title:"Chateau de Sable",src:"https://www.youtube.com/embed/Kr3Cy4fwQ6U"},
        {title:"Danny and the Wild Bunch",src:"https://www.youtube.com/embed/0B4KC6JkMhI"},
        {title:"Elysium Trailer",src:"https://www.youtube.com/embed/DIW-0K-GNkE"},
        {title:"Matrix (original audio)",src:"https://www.youtube.com/embed/KnPkroR6sZ0"},
        {title:"Norwegian Landscapes",src:"https://www.youtube.com/embed/O18AXyU-oJ8"}
      ],
      selectedVideo:""
    }
  },
  methods:{
    thumbnailURL(name){
      return this.dir+name
    },
    playVideo($event){
      console.log($event);
      $event.explicitOriginalTarget.click();
    },
    formatTitle(title){
      return title.replace(' ','-').toLowerCase()
    },
    togglePlayer(title){
      this.ind = this.albums.findIndex(album=>album.title==title)
      this.audioPlayerVisible=true
    },
    makeSelectedVideo(title){
      this.selectedVideo=title;
    }
  },
  computed:{
    currentAlbum(){
      return this.albums[this.ind]
    },
    albums(){
      return this.$store.state.albums
    }
  }

}

</script>

<style lang="scss" scoped>
.listen{
  position:fixed;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  z-index:0;
  background:black;
  color:rgb(226, 226, 226);

  .placeholder-img-container{
    width:100%;
    height:100%;
    display:block;
    overflow:hidden;
    display:flex;
    align-items:center;
    justify-content: center;
  }

  .placeholder-img{
    object-fit:cover;
    transition:0.25s;
    cursor:pointer;
    display:block;
    margin:auto;

    &:hover{
      opacity:0.5;
      transform:scale(1.05);
      
    } 
  }

  .internal{
    .placeholder-img{
      width:100%;
    }
  }

  .external{
    .placeholder-img{
      height:100%;
      width:100%;
    }
  }
  
  & .listen-grid{
    display:grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    width:100%;
    height:calc(100vh - 6rem);

      & .sidebar{
      width:100%;
      height:100%;
      background:black;
       a{
          text-decoration: none;
          color:inherit;
        }

      h2{
        text-align:left;
        padding:1rem;
        width:80%;
        margin:auto;
        margin-bottom: 1rem;
        border-radius: 1rem;
        padding-right:0.3rem;
        font-weight: 300;
        background:rgb(29, 29, 29);
        border-bottom:1px solid rgba(0, 0, 0, 0.267);
        transition: 0.2s;
        &:hover{
          color:rgb(52, 63, 221);
          background:rgb(1, 7, 37);
        }

       
      }

    }

    & .main{
      width:100%;
      height:100%;
      overflow:scroll;
      background:rgb(10, 10, 10);
    }
  }

  .album-image{
    width:20vw;
    height:20vw;
    min-width:80px;
    min-height:80px;
    object-fit: cover;
    cursor:pointer;
    transition:0.2s;
    &:hover{
      transform:translateY(-.5rem);
      opacity:0.6;
    }
    &:active{
      transform:translateY(-.25rem);
      opacity:0.5;

    }
  }

.video-grid{
  display:grid;
  column-gap: 1px;
  row-gap: 4rem;
  grid-template-columns:50% 50%;
  width:100%;
}
@media only screen and (max-width:700px){
  .video-grid{
    grid-template-columns: 100%;
  }
  .video-container{
    height:calc(50vw + 1rem);
    overflow:hidden;
  }
  .heading-primary{
    font-size:2rem;
  }
  h2{
    font-size:1.3rem;
  }
}

  
}

  #albums{
    animation:fadein 0.3s backwards ease-in;
    animation-delay: 0;
  }
  #film-scores{
    animation:fadein 0.3s backwards ease-in;;
    animation-delay: 0;
  }


.video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height:calc(30vw + 0.5rem);
  background:black;
}

.media-description{
  font-size:1.5rem;
  height: fit-content;
  background:rgb(22, 22, 22);
  color:rgb(218, 218, 218);
  padding:0.5rem;
  width:100%;
  text-align: center;
  font-weight: 300;
  z-index:1;
}

.gradient{
  &:not(:first-child){
    margin-top:5rem;
  }

}

</style>