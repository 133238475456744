<template>
    <div class="about-me-container">
    <slot></slot>
    </div>
</template>



<style lang="scss" scoped>
    .about-me-container{
        background-image:linear-gradient(270deg,rgba(53, 42, 42, 0.507),rgba(17, 5, 5, 0.589)), url('../../assets/kauffman-center.jpg');
        width:100vw;
        margin-top:-10rem;
        padding-top:10rem;
        min-height:100vh;
    }
</style>