<template>
    <div class="score-element">
        <img @click="showScore" class="preview-score" :src="dir+'preview-score-1.png'" alt="">
        <div class="title-description">
             <h3 class="score-title">{{title}}</h3>
             <p class="score-duration">{{duration}} minutes, {{genre}}</p>
             
        <p class="badge-row"><span class="inst-badge" v-for="instrument in instruments" :key="instrument">{{instrument}}&nbsp;</span></p>
        
        <p class="score-description">{{description}}</p>
        
        <div class="button-row">

            <button v-if="!$store.getters.addedIDs.includes(score.id)" @click="$store.commit('addItem',score)" class="btn">${{price}}</button>
            <button v-else class="btn">Added to cart!</button>
            <span @click="showScore" class="anchor-link" >Preview Score</span>
        </div>
        
        
        </div>
       
    </div>
</template>

<script>
export default {
    emits:['show-score'],
    props:['score'],
    data(){
        return{
            dir:this.$store.state.URL+"media/",
            price:this.score.price,
            duration:this.score.duration,
            genre:this.score.genre,
            instruments:this.score.instruments,
            title:this.score.title,
            description:this.score.description,
            PDF:this.score.PDF,
            mp3:this.score.MP3,
            id:this.score.id,
        }
    },
    methods:{
        showScore(){
            this.$emit('show-score')
        }
    },
    mounted(){
        setTimeout(()=>{
            window.scrollTo(0,0)
        },10)
    }
}
</script>

<style lang="scss" scoped>
    .score-element{
        background:linear-gradient(rgb(14, 14, 14),black);
        min-height:10rem;
        height:100%;
        display:flex;
        flex-direction: row;
        padding:1rem;
        border-radius:0.3rem;
        align-items: center;
    }
    .preview-score{
        height:8rem;
        transition:0.3s;
        cursor:pointer;
        &:hover{
            opacity:0.6;
            transform:translateY(-0.5rem);
        }
        @media screen and (max-width:500px) {
            display:none;
        }
  
    }
    .title-description{
        padding:0.5rem;
        font-size:0.9rem;
         font-weight:300;
    }
    .score-title{
        font-size:1rem;
       
    }
    .score-description{
        margin:1rem 1rem 1rem 1rem;
        color:grey;
    }
    .score-duration{
        text-align: center;
        letter-spacing: 0.5px;
        font-weight:300;
        margin-bottom: 0.5rem;
        color:rgb(182, 182, 182);
        
    }
    .badge-row{
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .btn{
        margin-top:0.5rem;
    }
    .button-row{
        width:100%;
        display:flex;
        justify-content: center;
        align-items: center;
        span{
            display: none;
        }
        @media screen and (max-width:500px) {
            span{
                display: inline;
            }
        }
        
    }
    .inst-badge{
        background:rgb(38, 80, 38);
        font-size:0.7rem;
        color:white;
        padding:0.5rem 1rem 0.5rem 1rem;
        margin-left:0.5rem;
        margin-top:0.5rem;
        border-radius: 1rem;;
        text-transform: uppercase;
        font-weight:300;
    }
   
</style>