<template>

    <div class="path">
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>

.path{
    margin-top:5rem;
    clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);
    width:100vw;
    min-height:130vh;
    padding-top:20vh;
    padding-bottom:40vh;
    background:linear-gradient(300deg, rgb(0, 0, 0) 20%,rgb(53, 53, 53) 100%)
}
</style>