<template>
    <div class="card">
    <slot>

    </slot>
    </div>
</template>

<style lang="scss" scoped>
    .card{
        margin:auto;
        margin-top:1rem;
        margin-bottom:1rem;
        color:rgb(228, 228, 228);
        background-color:var(--base-blue-ultradark);
        box-shadow:1px 3px 8px rgba(0, 0, 0, 0.315);
        border-radius:5px;
        width:90%;
        height:100%;
        padding:1rem;
        position:relative;
    }

</style>