<template>
    <div>
        <dark-card class="gradient-dark">
            <h1 style="font-weight:300;" class="heading-primary">Oops!</h1>
            <br>
            <base-card-white style="width:75%; min-width:250px;">
                <p>Something went wrong... Maybe try browsing some of my scores?</p>
            </base-card-white>
          <br>
        </dark-card>
    </div>

</template>

<script>
import BaseCardWhite from '../components/ui/BaseCardWhite.vue'
import DarkCard from '../components/ui/DarkCard.vue'
export default {
  components: { DarkCard, BaseCardWhite },
    
}
</script>

<style lang="scss" scoped>
p{
    font-size:2rem;
    padding:1rem;
    text-align:center;
    font-weight:200;
    color:rgb(65, 65, 65);
}
// .gradient-dark{
//     background-image:linear-gradient(100deg, rgb(0, 0, 90) 0%, rgb(0, 0, 90) 55%, rgb(0, 0, 39) 55%)
// }
</style>