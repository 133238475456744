<template>
    <div class="composition-root">
        <div class="carousel-container">
             <div style="width:75%;min-width:320px;height:50vh; margin:auto;overflow:hidden;">
                <carousel
                    :items='[
                    `<img style="object-fit:cover;width:100%;height:100%;" src="${dir}microphones-pic.jpg" alt="stock image">`,
                    `<img style="object-fit:cover;width:100%;height:100%;" src="${dir}diffuser-edited.png" alt="stock image">`,
                    `<img style="object-fit:cover;width:100%;height:100%;" src="${dir}mobile-recording-2.jpg" alt="stock image">`,
                    `<img style="object-fit:cover;width:100%;height:100%;" src="${dir}mixing-background.png" alt="stock image">`,
                    `<img style="object-fit:cover;width:100%;height:100%;" src="${dir}mobile-recording-3.jpg" alt="stock image">`,
                
                 
                    ]'
                ></carousel>
            </div>
                <div style="transform:translate(-50%,-150%);" class="hero-message">
                    <div class="relative-wrapper">
                        <h2>Professional sound.</h2>
                        <h1>Audio Engineering</h1>
                    </div>
                </div>
        </div>
        <dark-card style="width:100%;margin-top:-0.75rem;" class="gradient-dark">
            <br>
            <h1 style="font-weight:300" class="heading-primary">Audio Services</h1>
            <h3 style="font-weight: 300;text-align:center;" class="heading-secondary">Here's what I can do for you.</h3>
            <br>
            <div class="cards">
            <base-card  style="width:100%;overflow:hidden;">
            <div class="giant-ball"> </div>
            <i class="fas fa-microphone-alt"></i>
                <h2 class="heading-secondary">Recording</h2>
                <p class="description">I can provide location sound, mobile recording, or studio recording. My studio is sound-treated and includes space for about 3-4 musicians.</p>
            </base-card>

                <base-card style="width:100%;overflow:hidden;">
                <div class="giant-ball"> </div>
                    <i class="fas fa-wave-square"></i>
                    <h2 class="heading-secondary">Sound Design</h2>
                    <p class="description">I can provide custom foley and sound effects. Additionally, I can also edit location-sound, ADR, and sound recordings.</p>
                </base-card>
                <base-card style="width:100%;overflow:hidden;">
                <div class="giant-ball"> </div>
                <i class="fas fa-headphones"></i>
                    <h2 class="heading-secondary">Mixing &amp; Mastering</h2>
                <p class="description">I can mix and master your single, demo, album, or other recording. Rates are flexible and decrease with quantity of work.</p>
                </base-card>
                <base-card style="width:100%;overflow:hidden;">
                <div class="giant-ball"> </div>
                <i class="fas fa-podcast"></i>
                    <h2 class="heading-secondary">Audiobooks and Podcasts</h2>
                <p class="description">I can provide production sound for your audiobook, podcast, or speech recording. My toolset includes professional grade noise reduction,
                    editing, and mastering software.
                </p>
                </base-card>
            </div>
            <br> <br> <br><br>
        </dark-card>
        <div class="dark-section">
            <img :src="dir+'guitar-image.jpg'" alt="">
            <white-card class="gradient main-content">
                <h1 style="font-weight:300" class="heading-primary">Equipment</h1>
            <h3 style="font-weight: 300;text-align:center;" class="heading-secondary">Here's what I use.</h3>
            <router-link style="text-decoration:none;" to="/contact">
            <button class="btn">Book Now</button>
            </router-link>
            
            <br><br>
            <h4>Recording and Mixing</h4>
                <p> Audio is recorded through an Antelope Zen Tour interface via stock preamps, a Golden Age Pre-73 preamp, Yamaha mixer preamps, and preamps on a Mackie Onyx Blackbird via ADAT.</p>

<p>
Microphone inventory includes the Audio Technica AT4050, Shure SM7b, Avantone CV-12, Shure KSM141 stereo pair, 2 Cascade Fathead II’s, Shure SM-57, Sennheiser e835, Sennheiser e902, and Behringer C-2 stereo pair.
</p>

<p>
External compressors include the Art Pro VLA II and Chameleon Labs 7720.
Video Camera is a Sony NEX-FS100 with a stationary tripod. For location sound, I use a Tascam DR-100 MKIII professional grade field recorder.
</p>

<p>
It is recommended that you bring your own instrument, but if needed, a few basic instruments and amps are available for you to use.
</p>
<br><br>
<h4>Instruments and Amps</h4>
<div style="display:grid; grid-template-columns:50% 50%">


<ul>
    <li>Vox Pathfinder 10 Amp</li>
    <li>Ibanez Troubadour Amp</li>
    <li>Epiphone Les Paul Special</li>
    <li>Yamaha Acoustic Guitar</li>
    <li>Korg Kross 88 keyboard</li>
    <li>Mandolin</li>
    <li>Ukulele</li>
  
</ul>
<ul>
    <li>Various hand percussion</li>
    <li>Cajon</li>
    <li>French Horn</li>
    <li>Flute/Piccolo</li>
    <li>Harmonica</li>
    <li>Pennywhistle</li>
    <li>Cello</li>
</ul>


</div>

        </white-card> 
        </div>
         <portfolio-card>
            <h2 class="heading-primary" style="font-weight:300;">Portfolio</h2>
            <h3 style="font-weight: 300;text-align:center;" class="heading-secondary">Listen on YouTube, or check out my Soundcloud below.</h3>
            <a style="text-decoration:none;" target="_blank" href="https://www.youtube.com/user/Kopaka0111">
            <button class="btn">
            Listen on YouTube.
            </button>
            </a>
        </portfolio-card>
               <div class="soundcloud-grid">
                <div>
                    <white-card style="height:auto;width:100%;margin-bottom:-0.9rem;"><h2>Music Production</h2></white-card>
                    <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1102588537&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                </div>
                <div>
                    <white-card style="height:auto;width:100%;margin-bottom:-0.9rem;"><h2>Audio Engineering</h2></white-card>
                    <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1096588258&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                </div>
                
            </div>
                
        
        
    </div>
</template>

<script>
import Carousel from '../components/composition/Carousel.vue'
import BaseCard from '../components/ui/BaseCardWhite.vue'
import WhiteCard from '../components/ui/WhiteCard.vue'
import DarkCard from '../components/ui/DarkCard.vue'
import PortfolioCard from '../components/ui/PortfolioCard.vue'

export default {
  components: { Carousel, WhiteCard, BaseCard, DarkCard, PortfolioCard},
  data(){

  },
  computed:{
      dir(){
          return this.$store.state.URL+'media/'
      }
  }
    
}
</script>

<style lang="scss" scoped>
p{
    margin:1rem;
}
.btn{
  background:rgb(4, 9, 75);
  color:rgb(226, 226, 226);
  font-size:1.2rem;
  text-decoration: none;
  display:block;
  padding:1rem;
  margin:auto;
 
}
ul{
    padding:1rem;
    li{
        margin-left:2rem;
    }
}
.cards{
   
    display:grid;
    grid-template-columns: 45% 45%;
    column-gap: 4%;
    width:100%;
    row-gap:1.5rem;
    justify-content: center;
     @media only screen and (max-width:800px){
          grid-template-columns: 100%;
 
        }
    & p{
        z-index:2;
        position:relative;
        
    }
    & h2{
        font-size:1.8rem;
        text-align:center;
        position:relative;
        width:65%;
        margin:auto;
        margin-bottom:1rem;
        margin-top:0rem;
        padding-bottom: 1rem;
        color:rgb(29, 29, 29);
        border-bottom:1px solid rgba(128, 128, 128, 0.342);
    }
 
  
    i{
        
        background:rgb(52, 63, 221);
        color:rgb(26, 26, 26);
        height:4rem;
        width:4rem;
        padding:1rem;
        font-size:2rem;
        border-radius:50%;
        text-align:center;
        position:absolute;
        left:0.5rem;
        top:0.5rem;
        @media only screen and (max-width:800px){
            font-size:1.7rem;
            width:3.5rem;
            height:3.5rem;
 
        }


    }
    
}

.main-content{
    width:50%;
    min-width:300px;
    margin:auto;
}

.composition-root{
    margin-top:-2rem;
}
.carousel-container{
    background:black;
    width:100%;
    height:auto;
    box-shadow:0px 5px 10px rgba(0, 0, 0, 0.623);
    
}
.heading-secondary{
    text-align:left;
    margin:1rem;
}

.description{
        font-size:1rem !important;
        color:rgb(43, 43, 43) !important;
}
.dark-section{
    height:fit-content;
    overflow:hidden;
    width:100vw;
    position:relative;
    background:grey;
    margin-top:-1rem;
    & img{
        position: absolute;;
        width:100%;
    }
}
.giant-ball{
    background:rgb(192, 192, 192);
    width:100%;
    height:200%;
    border-radius:50%;
    position:absolute;
    bottom:2rem;
    left:0;

}

.samples-description{
    position:relative;
    padding-top:2rem;
    background:rgba(107, 107, 107, 0.568);
    padding-bottom:2rem;
}
h2{
    font-weight:500;
}

</style>