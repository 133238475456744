<template>
      <white-card style="overflow:hidden;">
            
              <h2 class="card-headers">{{title}}</h2>
              <img :src="getImgUrl()" >
              <br>
              <h3>{{descShort}}</h3>
              <br>
              <div class="advert-description" style="padding:0 1rem">
                  {{descLong}}
              </div>
              
                <br><br><br>
                <span class="center-button">
                  <router-link :to="'/'+link"><base-button class="btn clr-button">See More</base-button></router-link>
                
                </span>
           
          </white-card>
</template>

<script>
import WhiteCard from "./WhiteCard.vue"

export default {
    components:{WhiteCard},
    props:['imageName','title','descShort','descLong','link'],
    data(){
        return{
            dir:this.$store.state.URL+"media/",
            imageName:this.imageName,
            title:this.title,
            descShort:this.descShort,
            descLong:this.descLong,
            link:this.link || "",
            getImgUrl(){
                    return this.dir+this.imageName
            },
        }
    },
    
    mounted(){
        
    }
}
</script>

<style scoped>
    .clr-button{
        border: none;
        padding: 1rem 2rem 1rem 2rem;
        text-transform: uppercase;
        border-radius: 0.5rem;
        font-size: 0.9rem;
        font-weight: 600;
        transition: 0.25s ease-out;
        outline: none;
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.466);
        cursor: pointer;
        
        
    }
    a{
        text-decoration: none;
        margin-bottom: 1rem;
        display: block;
    }
    .advert-description{
        font-size:1.15rem;
    }
</style>
