<template>
   <white-card  style="height:auto;" class="gradient">
       <h2 class="heading-primary" style="font-weight:300;">Checkout</h2>
       <dark-card style="transition:0.3s;" id="dark-card-id" class="container">
         <i style="opacity:0;visibility:hidden;" id="spinner-icon" class="fas fa-spinner"></i>
         <br><br>
          <h3 class="heading-secondary" style="font-weight:300;">Total: ${{$store.getters.currentPrice}}</h3>
         <div style="width:50%;min-width:200px;margin-top:30px;" id="paypal"></div>
       </dark-card>
     </white-card>
</template>

<script>
import WhiteCard from '../components/ui/WhiteCard'
import DarkCard from '../components/ui/DarkCard'
// import * as paypal from '../scripts/paypal'
export default {
  components:{
    WhiteCard,
    DarkCard,

  },
  mounted(){
    let state = this.$store.state
    let cartPrice = this.$store.getters.currentPrice
    let router = this.$router

    // let cartItems = this.$store.state.cartItems
    // let downloads = this.$store.state.downloads
    // let downloadLinks = this.$store.state.downloadLinks

    // eslint-disable-next-line
    console.log(paypal)
      paypal.Buttons({
    createOrder: function(data, actions) {
      // This function sets up the details of the transaction, including the amount and line item details.
      return actions.order.create({
        purchase_units: [{
          item:{
            name:"JDS Music",
            quantity:1,
            unit_amount:{
              value:cartPrice+"$",
              currency_code:"USD"
            },
            description:"Digital Download",

          },
          amount: {
            value: cartPrice
          }
        }]
      });
    },
    onCancel: function() {
           document.getElementById('dark-card-id').classList.remove('halfOpacity')
        document.getElementById('spinner-icon').style=`
          opacity:0;
          visibility:hidden;
        `
    },
    onApprove: function(data, actions) {
      document.getElementById('dark-card-id').classList.add('halfOpacity')
        document.getElementById('spinner-icon').style=`
          opacity:1;
          visibility:visible;
        `
      // This function captures the funds from the transaction.
      return actions.order.capture().then(async function(details) {
        // This function shows a transaction success message to your buyer.
        let itemIDs = []
        for(let item of state.cartItems){
          itemIDs.push(item.id)
          state.downloads.push(state.downloadLinks[item.id])
        }

        console.log(state)
     
        
        
        const response = await fetch('https://josiahspranklemusic.com/app/orders',{
          //okay. for some reason, it is fetching 'localhost' instead of my website. No clue why. 
        // const response = await fetch(state.URL+'orders',{
          method:'POST',
          body:JSON.stringify({
            emailAddress:details.payer.email_address,
            itemIDs:itemIDs,
            ...details
          }),
          headers:{
            'content-type':'application/json',
            'accept':'application/json',
            'secret-key':'jdsmusic20371247'
          }
        })
      
        if(response.ok){
          const result = await response.json()
          state.uniqueURL=result.uniqueURL
          console.log(result)
          state.cartItems=[]
          router.push('/thank-you-order')
        }
        
        
      });
    }
  }).render('#paypal');

  }
}
</script>

<style lang="scss" scoped>
.container{
  display:grid;
  grid-template-columns: 100%;
  justify-items: center;
  height:fit-content;
  background: rgb(29, 29, 29);
}
#spinner-icon{
  font-size:5rem;
  transform:translate(-50%, -50%);
  top:40%;
  left:50%;
  position:absolute;
  animation:spin 2s infinite;
  transition:0.3s;
  z-index:1000000000000000000000;

}
@keyframes spin{
  0%{
    transform:translate(-50%, -50%) rotateZ(0deg) scale(1);
  }
  50%{
transform:translate(-50%, -50%) rotateZ(180deg) scale(1.6);
  }
   100%{
    transform:translate(-50%, -50%) rotateZ(359deg) scale(1);
  }
}
.halfOpacity{
  opacity:0.5;
}
</style>