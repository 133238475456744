<template>
    <svg :id="waveformID" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 256 320" enable-background="new 0 0 256 256" xml:space="preserve">
<g>
    <path d="M12,64H4c-2.2,0-4,1.8-4,4v120c0,2.2,1.8,4,4,4h8c2.2,0,4-1.8,4-4V68C16,65.8,14.2,64,12,64z"/>
    <path d="M36,48h-8c-2.2,0-4,1.8-4,4v152c0,2.2,1.8,4,4,4h8c2.2,0,4-1.8,4-4V52C40,49.8,38.2,48,36,48z"/>
    <path d="M60,0h-8c-2.2,0-4,1.8-4,4v248c0,2.2,1.8,4,4,4h8c2.2,0,4-1.8,4-4V4C64,1.8,62.2,0,60,0z"/>
    <path d="M108,64h-8c-2.2,0-4,1.8-4,4v120c0,2.2,1.8,4,4,4h8c2.2,0,4-1.8,4-4V68C112,65.8,110.2,64,108,64z"/>
    <path d="M84,32h-8c-2.2,0-4,1.8-4,4v184c0,2.2,1.8,4,4,4h8c2.2,0,4-1.8,4-4V36C88,33.8,86.2,32,84,32z"/>
    <path d="M132,80h-8c-2.2,0-4,1.8-4,4v88c0,2.2,1.8,4,4,4h8c2.2,0,4-1.8,4-4V84C136,81.8,134.2,80,132,80z"/>
    <path d="M180,64h-8c-2.2,0-4,1.8-4,4v120c0,2.2,1.8,4,4,4h8c2.2,0,4-1.8,4-4V68C184,65.8,182.2,64,180,64z"/>
    <path d="M204,80h-8c-2.2,0-4,1.8-4,4v88c0,2.2,1.8,4,4,4h8c2.2,0,4-1.8,4-4V84C208,81.8,206.2,80,204,80z"/>
    <path d="M156,48h-8c-2.2,0-4,1.8-4,4v152c0,2.2,1.8,4,4,4h8c2.2,0,4-1.8,4-4V52C160,49.8,158.2,48,156,48z"/>
    <path d="M228,96h-8c-2.2,0-4,1.8-4,4v56c0,2.2,1.8,4,4,4h8c2.2,0,4-1.8,4-4v-56C232,97.8,230.2,96,228,96z"/>
    <path d="M252,112h-8c-2.2,0-4,1.8-4,4v24c0,2.2,1.8,4,4,4h8c2.2,0,4-1.8,4-4v-24C256,113.8,254.2,112,252,112z"/>
</g>
</svg>
</template>
<script>
    export default {
        props:["fill"],
        data(){
            return{
                fill:this.fill || "white",
                waveformID:"WAVE"+Math.round(Math.random()*100000)
            }
        },
        mounted(){

                document.querySelectorAll(`#${this.waveformID}>g>*`).forEach(elem=>{
                    let transform = (Math.random()*20) + 20;
                    let num = 5+(Math.random()*5)
                    let inc = 1;
                    elem.style.transformOrigin = 0
                    elem.style.fill = this.fill || "black";
                    setInterval(()=>{
                        num = 6+(Math.random()*14)
                        transform+=inc;
                        if(transform > 50){
                            inc = -1;
                        }
                        if(transform < 0){
                            inc = 1;
                        }
                        elem.style.transform=`rotateX(${transform}deg)`;
                    },num)
                })

            
        }
    }
</script>

<style scoped>

</style>
