<template>
  <div class="cart">
     <white-card id="white-card" style="min-width:300px;" class="gradient">
       <h2 class="heading-primary" style="font-weight:300;">Cart</h2>

       <dark-card id="dark-card"  style="min-width:300px;" class="grid">
          <div class="grid-left"> <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
            <svg class="cart-logo-1" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="475.084px" height="475.085px" viewBox="0 0 475.084 475.085" style="enable-background:new 0 0 475.084 475.085;"
              xml:space="preserve">
            <g>
              <g>
                <path d="M365.446,401.998c0,10.092,3.579,18.702,10.711,25.834c7.132,7.139,15.749,10.711,25.845,10.711
                  c10.081,0,18.698-3.572,25.83-10.711c7.139-7.132,10.711-15.742,10.711-25.834s-3.568-18.702-10.711-25.841
                  c-7.132-7.132-15.749-10.704-25.83-10.704c-10.096,0-18.713,3.572-25.845,10.704C369.025,383.296,365.446,391.906,365.446,401.998
                  z"/>
                <path d="M469.658,78.51c-3.618-3.617-7.898-5.426-12.848-5.426H113.918c-0.193-1.331-0.621-3.756-1.287-7.277
                  c-0.666-3.523-1.188-6.329-1.569-8.425c-0.383-2.087-1.093-4.611-2.142-7.561c-1.047-2.952-2.284-5.286-3.711-6.995
                  c-1.425-1.718-3.328-3.189-5.708-4.43c-2.378-1.233-5.092-1.853-8.136-1.853H18.276c-4.952,0-9.234,1.812-12.85,5.424
                  C1.809,45.583,0,49.868,0,54.816s1.809,9.231,5.426,12.847c3.619,3.617,7.902,5.424,12.85,5.424h58.237l50.532,234.976
                  c-0.378,0.76-2.329,4.373-5.852,10.848c-3.521,6.475-6.328,12.135-8.42,16.988c-2.093,4.859-3.14,8.616-3.14,11.279
                  c0,4.948,1.809,9.232,5.424,12.854c3.621,3.606,7.902,5.421,12.851,5.421h18.272h255.815h18.261c4.948,0,9.232-1.814,12.847-5.421
                  c3.62-3.621,5.427-7.905,5.427-12.854c0-4.949-1.807-9.233-5.427-12.847c-3.614-3.614-7.898-5.428-12.847-5.428h-262.66
                  c4.57-9.138,6.854-15.222,6.854-18.268c0-1.909-0.238-4.004-0.715-6.283s-1.047-4.805-1.713-7.569
                  c-0.667-2.752-1.093-4.799-1.283-6.133l298.077-34.831c4.753-0.575,8.658-2.614,11.703-6.14c3.046-3.518,4.565-7.562,4.565-12.133
                  V91.363C475.082,86.415,473.278,82.132,469.658,78.51z"/>
                <path d="M109.632,401.998c0,10.092,3.567,18.702,10.706,25.834c7.141,7.139,15.75,10.711,25.841,10.711
                  c10.085,0,18.699-3.572,25.835-10.711c7.139-7.132,10.71-15.742,10.71-25.834s-3.568-18.702-10.71-25.841
                  c-7.137-7.132-15.75-10.704-25.835-10.704c-10.09,0-18.704,3.572-25.841,10.704C113.203,383.296,109.632,391.906,109.632,401.998z
                  "/>
              </g>
            </g>
            </svg>
             <svg class="cart-logo-2" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="475.084px" height="475.085px" viewBox="0 0 475.084 475.085" style="enable-background:new 0 0 475.084 475.085;"
              xml:space="preserve">
            <g>
              <g>
                <path d="M365.446,401.998c0,10.092,3.579,18.702,10.711,25.834c7.132,7.139,15.749,10.711,25.845,10.711
                  c10.081,0,18.698-3.572,25.83-10.711c7.139-7.132,10.711-15.742,10.711-25.834s-3.568-18.702-10.711-25.841
                  c-7.132-7.132-15.749-10.704-25.83-10.704c-10.096,0-18.713,3.572-25.845,10.704C369.025,383.296,365.446,391.906,365.446,401.998
                  z"/>
                <path d="M469.658,78.51c-3.618-3.617-7.898-5.426-12.848-5.426H113.918c-0.193-1.331-0.621-3.756-1.287-7.277
                  c-0.666-3.523-1.188-6.329-1.569-8.425c-0.383-2.087-1.093-4.611-2.142-7.561c-1.047-2.952-2.284-5.286-3.711-6.995
                  c-1.425-1.718-3.328-3.189-5.708-4.43c-2.378-1.233-5.092-1.853-8.136-1.853H18.276c-4.952,0-9.234,1.812-12.85,5.424
                  C1.809,45.583,0,49.868,0,54.816s1.809,9.231,5.426,12.847c3.619,3.617,7.902,5.424,12.85,5.424h58.237l50.532,234.976
                  c-0.378,0.76-2.329,4.373-5.852,10.848c-3.521,6.475-6.328,12.135-8.42,16.988c-2.093,4.859-3.14,8.616-3.14,11.279
                  c0,4.948,1.809,9.232,5.424,12.854c3.621,3.606,7.902,5.421,12.851,5.421h18.272h255.815h18.261c4.948,0,9.232-1.814,12.847-5.421
                  c3.62-3.621,5.427-7.905,5.427-12.854c0-4.949-1.807-9.233-5.427-12.847c-3.614-3.614-7.898-5.428-12.847-5.428h-262.66
                  c4.57-9.138,6.854-15.222,6.854-18.268c0-1.909-0.238-4.004-0.715-6.283s-1.047-4.805-1.713-7.569
                  c-0.667-2.752-1.093-4.799-1.283-6.133l298.077-34.831c4.753-0.575,8.658-2.614,11.703-6.14c3.046-3.518,4.565-7.562,4.565-12.133
                  V91.363C475.082,86.415,473.278,82.132,469.658,78.51z"/>
                <path d="M109.632,401.998c0,10.092,3.567,18.702,10.706,25.834c7.141,7.139,15.75,10.711,25.841,10.711
                  c10.085,0,18.699-3.572,25.835-10.711c7.139-7.132,10.71-15.742,10.71-25.834s-3.568-18.702-10.71-25.841
                  c-7.137-7.132-15.75-10.704-25.835-10.704c-10.09,0-18.704,3.572-25.841,10.704C113.203,383.296,109.632,391.906,109.632,401.998z
                  "/>
              </g>
            </g>
            </svg>
            <br>
            <h3 style="text-align:left; color:white;" class="heading-secondary">Your Shopping Cart</h3>
            <br>
            <p class="description">After purchase, you will find everything you need available as a digital download. Most downloads are single file, but if you are purchasing an album or large orchestral score, the files will compressed into a zip file.</p>
      
            </div>
          <div class="grid-right">
             <ShoppingCart />
             <div>
                 <br>
                <h3 style="font-weight:300;">Total: ${{$store.getters.currentPrice}}</h3>
                <br>
                <router-link v-if="$store.getters.quantity" style="text-decoration:none;" to='/checkout'> 
                <button style="display:block; margin:auto;" class="btn">Proceed to Checkout</button>
                </router-link>
                <button v-else style="display:block; margin:auto;" class="btn">No items yet.</button>
             </div>
             
            
          </div>
          

           


        
       </dark-card>
     </white-card>
  </div>
</template>

<script>
import WhiteCard from '../components/ui/WhiteCard'
import DarkCard from '../components/ui/DarkCard'
import ShoppingCart from '../components/complex/ShoppingCart'
export default {
  components:{
    WhiteCard,
    DarkCard,
    ShoppingCart
  }
}

</script>

<style lang="scss" scoped>

#dark-card{
  background:rgb(32, 32, 32);
}
.cart{
  margin-top:-2rem;
  position:relative;

 

 
  .grid{
    display:grid;
    
    grid-template-columns: 35% 65%;
    grid-template-rows: auto;
    column-gap: 0.5rem;

  


    .grid-left{
      padding:0.5rem;
      border-right:1px solid rgba(128, 128, 128, 0.151);
    }
    .grid-right{
      padding:0.5rem;
      padding-bottom:2rem;
      width:100%;
      min-height:55vh;
      position: relative;
      display:grid;
      grid-template-columns: 100%;
      grid-template-rows: 80% 20%;
    }

  }

  .cart-logo-1{
    fill:var(--base-blue-light);
    width:8rem;
    height:8rem;
    margin-top:2rem;
    margin-bottom:2rem;
    margin-left:2rem;
    display:inline-block;

    
  }
  .cart-logo-2{
    fill:var(--base-blue);
    width:3rem;
    height:3rem;
    display:inline-block;
    transform:translateY(-6rem) translateX(1rem);

  }

}

@media screen and (max-width:900px){
    .cart .grid{
      grid-template-columns:100%;
      grid-template-rows:auto auto;

    }
  }

@media screen and (max-width:450px){
   #white-card{
     padding:0;
     margin:0.9rem 0rem 2rem 0rem;
     width:100%;
   }
   #dark-card{
     padding:1rem;
     margin:0;
     width:100%;
   }
  }



</style>