<template>
    <div class="piano-performance">
        <div class="relative-wrapper">
             <img :src="dir+'playing-piano.jpg'" alt="">
             <br>
             <br>
             <br>
             <br>
             <br>
              <white-card style="width:60%;min-width:250px;height:auto;margin-bottom:2rem;">
                <h2 class="heading-primary" style="font-weight:300;">Piano</h2>
                
                
                <h3 style="font-weight: 300;text-align:center;" class="heading-secondary">Listen on YouTube, or check out my listening page.</h3>
                <br>
                <a style="text-decoration:none;" target="_blank" href="https://www.youtube.com/user/Kopaka0111">
                <button class="btn">
                Listen on YouTube.
                </button>
                </a>
                </white-card>
                <dark-card style="width:60%;min-width:250px;height:auto;">
                    <p class="description">I am available to anyone who needs a pianist, whether it be as an accompanist, a session musician, a gig, or
                        background music for your event. I offer accompanist services to high schoolers or collegiate
                        music students seeking auditions, I'm available as a session musician to play on your album. To book me,
                        <router-link class="anchor-link" to="/contact">contact me here.</router-link>
                    </p>
                </dark-card>
        
        </div>
    </div>
</template>

<script>
import WhiteCard from '../components/ui/WhiteCard.vue'
import DarkCard from '../components/ui/DarkCard.vue'
import VideoPlayer from '../components/composition/VideoPlayer.vue'
export default {
  components: { WhiteCard, DarkCard, VideoPlayer },
    
    computed:{
      dir(){
          return this.$store.state.URL+'media/'
      }
  }
}
</script>

<style lang="scss" scoped>

h2{
    
}

h3{
    color:rgb(34, 34, 34);
    font-weight:100;
}
.piano-performance{
    width:100vw;
    height:100vh;
    position: fixed;
    top:0;
    left:0;
    
    img{
        position:absolute;
        width:100%;
        height:100%;
        object-fit: cover;
        filter:contrast(75%) blur(2px) brightness(70%);
        
    }
    .btn{
        color:rgb(226, 226, 226);
        font-size:1rem;
        text-decoration: none;
        display:block;
        padding:1rem;
        margin:auto;

    }
    p{
        margin:1rem;
       
    }
}

.description{
     font-size:1.2rem !important;
}
    
</style>