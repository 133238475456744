<script>
export default {
    data(){
        return{
            dir:this.$store.state.URL+"media/"
        }
    },
    mounted(){
        if(localStorage.images){
            this.$store.state.images = JSON.parse(localStorage.images);
        }else{
            this.$store.state.images = {
            
            }
        }
        
    }
}
</script>