<template>
<div id="root">
  <div id="top-of-page"></div>
  <TheHeader class="fadein" v-if="$store.state.loaded"/>
  <div class="margin-5"></div>
  <br>
  <img :style="{opacity:$route.path.replace('/app','') == '/' | !$store.state.loaded ? '0' : '1'}" class="bgd-image" src="./assets/background.jpg" alt="">
  
  <transition mode="out-in" name="fade">
  <router-view></router-view>
  </transition>
  <div :class="{'hide-overlay':$store.state.loaded,'loading-overlay':true}">
      <div class="waveform-container">
      <WaveForm fill="white" />
    </div>
  </div>
  
  
<Preload/>
</div>
</template>

<script>

import TheHeader from './components/layout/TheHeader'
import WaveForm from './assets/waveform.vue'
import Preload from './scripts/Preload.vue'

export default {
  components:{
    TheHeader,
    WaveForm,
    Preload
  },
  data(){
    return{
      loaded:false,
      isHome:function(){return window.location.path.replace("/app","") == "/"},
    }
  },
  methods:{
    setCart(){
      if(localStorage.cartItems){
        try {
          this.$store.state.cartItems  = JSON.parse(localStorage.cartItems)
        } catch (error) {
          console.error(error)
        }
      }
    },
  },
  watch:{
    $route (to, from){
        console.log(to);
    }
  },
  created(){
    
  },
  async mounted(){
    let s = this.$store.state
    this.setCart();
    window.location.port=='8080' ? s.URL = 'http://localhost:3000/app/' : s.URL = '/app/'
    console.log(s.URL)
    const response = await fetch(s.URL+'api/products')
    const scoresAndAlbums = await response.json()
    s.scores=[...scoresAndAlbums.scores]
    s.albums=[...scoresAndAlbums.albums]
    s.downloadLinks={...scoresAndAlbums.downloadLinks}
    setTimeout(()=>{
      this.$store.state.loaded = true;
    },2000);



    
  }

}
</script>

<style lang="scss">
$color-primary-light:rgba(52, 55, 219, 0.829);
$color-primary-dark:rgb(38, 53, 187);
$color-primary-main:rgb(0, 6, 97);

$color-black:rgb(29, 29, 29);
$color-white:rgb(226, 226, 226);
$color-grey-light:rgb(187, 87, 87);
$color-grey-dark:rgb(49, 49, 49);

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');

.kaushan{
    font-family: 'Kaushan Script', cursive;
}
.roboto{
    font-family: 'Roboto', sans-serif;
}

:root{
  --base-blue:rgb(22, 73, 131);
  --base-blue-dark:rgb(24, 53, 85);
  --base-blue-ultradark:rgb(12, 35, 61);
  --base-blue-light:rgb(74, 136, 207);
}

*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

html{    
    touch-action: manipulation;
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: transparent;
}



body{
    width:100vw;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background-color: black;
}

.relative-wrapper{
    position:relative;
    width:100%;
    height:100%;
}

h1,h2,h3,h4,h5,h6{
    text-align:center;
}

@media screen and (max-width:1200px){
    html{
        font-size:95%;
    }
        
}

@media screen and (max-width:900px){
    html{
        font-size:90%;
    }
        
}

@media screen and (max-width:600px){
    html{
        font-size:85%;
    }
        
}

.hero-action{
  position: absolute;
  width:fit-content;
  height:fit-content;
  top:55%;
  left:50%;
  transform:translate(-50%,-50%);
   @media only screen and (max-width: 440px){
    top:56%;
  }
}

.hero-message{
  position: absolute;
  width:fit-content;
  height:fit-content;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);

  h1{
    text-transform: uppercase;
    background:rgba(255, 255, 255, 0.699);
    box-sizing: content-box;
    padding:1rem;
    font-weight:400;
    font-size:2.25rem;
    border-radius:0.3rem;
    letter-spacing:200%;
    
  }
  h2{
    font-size:1.5rem;
    color:white;
    font-weight:400;
    text-transform: uppercase;
    margin-bottom:1rem;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.733);;
  }
  @media only screen and (max-width: 440px){
    h1{
      font-size:1.5rem;
    }
    h2{
      font-size:1.25rem;
    }
  }
}


.anchor-link{
  margin-left:0.5rem; 
  margin-top:0.3rem;
  text-decoration:underline; 
  cursor:pointer;
  transition:0.3s;
  &:hover{
    opacity:0.6;
  }
}

#root{
  color:black;
  width:100vw;
  height:100vh;
  overflow-x:hidden;
  position:relative;

  .bgd-image{
    filter: contrast(50%) brightness(60%);
    position:absolute;
    width:100%;
    max-height:130vh;
    min-height:100vh;
    top:0;
    left:0;
    z-index:-1;
  }

  .margin-5{
    height:5.5rem;
  }


  .waveform-icon{
    width:7rem;
    height:7rem;
    object-fit: fill;
    display:block;
    margin:auto;
    padding:0.5rem;
    /* background:rgb(52, 63, 221); */
    background:var(--base-blue-light);
    border-radius:50%;

    svg{
      height:100%;
      display:block;
      margin:auto;
      margin-top:0.5rem;
    }

  }
  .description{
    font-weight:300;
    width:90%;
    color:rgb(230, 230, 230);
    min-width:220px;
    font-size:1rem;
  }
}

.heading-secondary{
  color:rgba(255, 255, 255, 0.822);
  font-weight: 600;
  font-size: 1.5rem;
}

.profile-picture{
    width:100%;
    display:inline-block;
  }
  #audio-services{
    img{
      width:5rem;
      display:block;
      margin:auto;
      margin-top:1rem;
      padding:0.2rem;
    }
  }
  .card-grid{
    display:grid;
    grid-template-columns: 33% 33% 33%;
    width:100%;
  }
  .card-headers{
    background:var(--base-blue-light);
    width:calc(100% + 2rem);margin-left:-1rem;margin-top:-1rem;padding:1rem;
  }

  .heading-primary{
    font-size:4rem;
  }

  .center-button{
    position:absolute; 
    bottom:1rem;
    left:50%;
    transform:translateX(-50%)
  }

  .gradient{
    background-image:linear-gradient(100deg, white 0%, white 55%, rgb(219, 219, 219) 55%)
  }

   .gradient-dark{
    background-image:linear-gradient(100deg, rgb(20, 20, 20) 0%, rgb(20, 20, 20) 55%, rgb(8, 8, 8) 55%)
  }

  #info{
    border-left: 1px solid rgba(128, 128, 128, 0.397);
    h3:not(:first-child){
      margin:auto;
      margin-top:1rem;
      border-top:1px solid rgba(128, 128, 128, 0.1);
      padding-top:0.75rem;
      width:90%;
      
    }
    p{
      background:var(--base-blue-dark);
      width:93% !important;
      line-height:1.75rem;
      margin:1rem auto;
      border-radius:10px;
    }
  }

  .p-wrapper{
      background:var(--base-blue-dark);
      width:93% !important;
      line-height:1.75rem;
      margin:1rem auto;
      border-radius:10px;
  }

  .profile-container{
    width:100%;
    height:100%;
    

    & img{
      max-width:100%;
      max-height:100%;
      display:block;
      margin:auto;
      object-fit: cover;
    }
  
  }
  .about-me-grid{
    padding:1rem;
    display:grid;
    width:100%;
    height:auto;
    grid-template-columns: 25% 75%;
    column-gap: 1rem;
    row-gap:1rem;
    box-sizing: border-box;
    justify-content: start;

    & p{
      padding:1rem;
      padding-right:2rem;
    }
  }
  .submit-button{
    background:var(--base-blue);
    color:rgb(231, 231, 231);
    border:none;
    margin-top:-0.15rem;
    border-radius:0.2rem;
    padding:0.75rem 1rem 0.75rem 1rem;
    font-size:1rem;
    width:100%;
    text-align:center;
    cursor:pointer;
    transition:0.3s;

    &:hover{
      background:var(--base-blue-dark);
    }
  }
  .icon-row{
    margin-top:1rem;
    margin-bottom:1rem;
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;

    & a{
      transition:0.3s;
    }
    & a:hover{
      opacity:0.6;
      transform:scale(1.1) translateY(-5px);
    }

  }
  .btn{
    border:none;
    background:var(--base-blue);
    color:rgb(207, 207, 207);
    border-radius:5px;
    font-size:0.9rem;
    padding:0.5rem 1rem 0.5rem 1rem;
    transition:0.1s;
    cursor:pointer;

    &:hover{
      opacity:0.7;
    }
  }
  .delete-btn{
    color:white;
    cursor: pointer;
  }

  .clr-button{
    background:var(--base-blue) !important;
    color:white !important;
  }
  
  button a{
    text-decoration: none;
    color:white;
  }



  @media screen and (max-width:800px){
      
      .card-grid{
        display:grid;
        height:fit-content;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;
        grid-row-gap:1rem;
        width:100%;
      }
      .about-me-grid{
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
      }
      .profile-container{
        & img{
          width:100%;
          max-width:200px;
          max-height:200px;
        }
      }
      #info{
          border-left: none;
          padding-top:1rem;
          border-top: 1px solid rgba(128, 128, 128, 0.397);
        }
  }


  .fade-up-enter-from{
    transform:translateY(-2rem);
    opacity:0;
  }
  .fade-up-enter-active{
    transition:0.75s;
  }
  .fade-up-enter-to{
    transform:translateY(0rem);
    opacity:1;
  }
  .fade-up-leave-from{
    transform:translateY(0rem);
    opacity:1;
  }
  .fade-up-leave-active{
    transition:0.75s;
  }
  .fade-up-leave-to{
    /* transform:translateY(-2rem); */
    opacity:0;
  }

  .fade-enter-from{
    transform:scale(1.05);
    opacity:0;
  }
   .fade-enter-active{
    transition:0.5s;
    position: absolute;
  }
   .fade-enter-to{
     transform:scale(1);
    opacity:1;
    
  }
   .fade-leave-from{
    transform:scale(0.95);
    opacity:1;
  }
   .fade-leave-active{
    transition:0.5s;
    position: absolute;
  }
   .fade-leave-to{
    transform:scale(1);
    opacity:0;
  }


   @keyframes fadein{
    0%{
      opacity:0;
    }
    100%{
      opacity:1;
    }
  }
         @keyframes fadeout{
        0%{
            opacity: 1;
            transform: translateY(0rem);
        }
        100%{
            opacity: 0;
            transform: translateY(5rem);
        }
    }

    .fadein{
        animation: fadein 0.5s backwards ease-in-out;
    }
    .fadeinslow{
        animation: fadein 0.5s backwards;
        animation-delay: 0.5s;
    }
    .fadeout{
        animation: fadeout 0.5s ease-in-out;
    }

    .slideright{
      animation:slideright 0.7s backwards ease-out;
      animation-delay: 0.3s;
    }

    .fade-back-and-forth{
      animation:fade-back-and-forth 1s linear infinite;
    }


    @keyframes fade-back-and-forth{
      0%{
        opacity:0.8;
      }
      50%{
        opacity:0.4;
      }
      100%{
        opacity:0.8;
      }
    }

    @keyframes slideright{
      0%{
        transform: translateX(-50%);
        opacity:0%;
      }
  
      100%{
        transform: translateX(0%);
        opacity:100%;
      }
    }

    /* CSS UTILITIES */

    .waveform-container{
      position:fixed;
      z-index:1001;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
      width:10rem;
      height:10rem;
      background:var(--base-blue);
      padding:2rem;
      border-radius:50%;

      &::after{
        content:"Loading...";
        color:rgb(230, 230, 230);
        width:100%;
        font-size:1.5rem;
        margin-top:2rem;
        display:block;
        text-align:center;
        animation:fade-back-and-forth 1s linear infinite;
      }
    }

    .loading-overlay{
        width:100vw;
        height:100vh;
        top:0;
        left:0;
        position:fixed;
        z-index:1000;
        background:black;
        transition:0.5s;
    } 

    .large-padding{
      padding:1rem;
    }

    .center{
      margin:auto;
      display:block;
    }

    .hide-overlay{
      transform:scale(200%);
      pointer-events: none;
      opacity:0;
    }

    .soundcloud-grid{
      width:92%; 
      margin:auto; 
      display:grid; 
      grid-template-columns:48% 48%; 
      column-gap:4%; 
      padding-top:2rem; 
      position:relative;
      @media only screen and (max-width:800px){
          grid-template-columns: 100%;

        }
    }

</style>
