<template>
<teleport to='body'>
    <div @keypress="close" class="fadein" id="pdf-viewer">
        <div class="top-bar">
            <button class="btn" id="prev-page"><i class="fas fa-arrow-circle-left"></i></button>
            <span class="page-info"><span id="page-num"></span> of <span id="page-count"></span>
            <span>
            <label class="mt-3" for="customRange3"></label>
            <input type="range" class="custom-range" min="50" max="200" step="1" value="100"  id="customRange3"></span>
            <button class="btn" id="next-page"><i class="fas fa-arrow-circle-right"></i></button>
             </span>    
        </div>
        <div class="render-grid">
            <div class="render-container">
                <canvas id="pdf-render"></canvas>
            </div>
            <div style="color:white;" id="pdf-description">
                <div @click="close" class="x-icon"><i class="fas fa-times-circle delete-btn"></i></div>
                <h1 style="font-weight:500;">{{score.title}}</h1>
                <p>{{score.description}}</p>
                <button v-if="!$store.getters.addedIDs.includes(score.id)" @click="$store.commit('addItem',score)" class="btn">${{score.price}}</button>
                <button v-else class="btn">Added to cart!</button>
            </div>
        </div>
        
        <div class="audio-container">
            <audio controls :src="dir+mp3"></audio>
        </div>
        
            
        
    </div>
    </teleport>
</template>

<script>
/* eslint-disable */
export default {
    emits:['close-pdf'],
    props:['score'],
    data(){
        return{
            dir:this.$store.state.URL+'media/',
            PDF:this.score.PDF,
            mp3:this.score.mp3,
            id:1,
        }
    },
    methods:{
        close(){
            document.getElementById('pdf-viewer').classList.add('fadeout')
            setTimeout(()=>{
                this.$emit('close-pdf')
            },500)
            
        }
    },
    mounted(){
        const url = this.dir+this.PDF
        let pdf = null,
        pageNum = 1,
        pageIsRendering = false,
        pageNumIsPending = null;

        let scale = document.getElementById("customRange3").value/100
        let canvas = document.querySelector('#pdf-render')
        let context = canvas.getContext('2d')
        let containerHeight = document.querySelector('.render-container').offsetHeight
        let resized = false

        

        // Render the page
        let renderPage = num => {
        pageIsRendering = true;

        // Get page
        pdf.getPage(num).then(page => {

        // Set scale
        let initial={}

     
        let viewport = page.getViewport({ scale:scale });

        if(viewport.height>containerHeight&&!resized){
            initial={
                height:viewport.height,
                width:viewport.width
            }
            let ratio = viewport.width/viewport.height
            while(viewport.height>containerHeight){
                viewport.height -= 1
                viewport.width -= ratio
            }
            let newScale = viewport.height/initial.height

            viewport = page.getViewport({ scale:newScale });
            let v = document.getElementById("customRange3").value
            document.getElementById("customRange3").value = parseInt(v*newScale)
            scale = newScale
            resized=true
        }
        
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        let renderCtx = {
            canvasContext: context,
            viewport
        };

        page.render(renderCtx).promise.then(() => {
            pageIsRendering = false;

            if (pageNumIsPending !== null) {
            renderPage(pageNumIsPending);
            pageNumIsPending = null;
            }
        });

        // Output current page
        document.querySelector('#page-num').textContent = num;
        });
        };

        // Check for pages rendering
        const queueRenderPage = num => {
        if (pageIsRendering) {
            pageNumIsPending = num;
        } else {
            renderPage(num);
        }
        };

        // Show Prev Page
        const showPrevPage = () => {
        if (pageNum <= 1) {
            return;
        }
        pageNum--;
        queueRenderPage(pageNum);
        };

        // Show Next Page
        const showNextPage = () => {
        if((pageNum == Math.round(pdf.numPages/2))){
            return
        }
        if (pageNum == Math.round(pdf.numPages/2)-1 ) {
            let grd = context.createRadialGradient(75, 50, 5, 90, 60, 100);
            grd.addColorStop(0, "white");
            grd.addColorStop(1, "#a0bae8");
            context.fillStyle = grd;
            context.fillRect(0,0,canvas.width,canvas.height)
            context.fillStyle = "black";
            context.font = "18pt Arial";
            context.fillText("Thanks for browsing!",10, 50);
            context.font = "12pt Arial";
            context.fillText("Please purchase to view full PDF.",10, 80);
            document.querySelector('#page-num').textContent++
            pageNum++
            return;
        }
        pageNum++;
        queueRenderPage(pageNum);
        };

        // Get Document
        pdfjsLib
        .getDocument(url)
        .promise.then(arg => {
            pdf = arg;

            document.querySelector('#page-count').textContent = Math.round(pdf.numPages/2);
            
            renderPage(pageNum);
        })
        .catch(err => {
            console.log(err)
        });

        // Button Events
        document.querySelector('#prev-page').addEventListener('click', showPrevPage);
        document.querySelector('#next-page').addEventListener('click', showNextPage);
        document.getElementById("customRange3").addEventListener('change',()=>{
            let val = document.getElementById("customRange3").value
            console.log(val)
            scale = val/100
            renderPage(pageNum)
        })
        }        
}
</script>

<style lang="scss" scoped>
*{
    margin:0;
    padding:0;
}

//pdf is 792 x 612, but a different PDF is a different size...

#pdf-viewer{
    width:100vw;
    height:100vh;
    position: fixed;
    top:0;
    left:0;
    z-index:10;
    background:linear-gradient(rgb(14, 14, 14),black);
}

.top-bar{
    background:rgb(34, 34, 34);
    color:white;
    padding:1rem;
    width:100vw;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height:4rem;

}

.btn{
    color:var(--base-blue);
    background-color:rgb(216, 216, 216);
    border:none;
    outline: none;
    cursor: pointer;
    padding:0.5rem 1rem;
    font-size:1rem;
    

}

.custom-range{
    margin-left:1rem;
    margin-right:1rem;
    transform:translateY(0.25rem);
    width:30vw;
    color:red;
}

.btn:hover{
    opacity:0.9
}

.page-info{
    margin-left:1rem;
}

.render-grid{
    height:calc(100vh - 6.6rem);
    width:100vw;
    grid-template-columns: 50% 50%;
    display:grid;
    
}
.render-container{ 
    height:100%;
    width:100%;
    min-width:320px;
    display:grid;
    justify-content: center;
    background:black;
    overflow: hidden;
}
.audio-container{
    width:100vw;
    height:2.5rem;
    background:rgb(34, 34, 34);


    audio{
        width:100%;
        height:2.5rem;
    }
}

.error{
    background:rgb(32, 32, 32);
    color:white;
    padding:1rem;
}

#pdf-description{
    position: relative;
       width:100%;
       h1{
           text-align:center;
           margin:0.5rem;
           margin-top:2rem;
           color:rgb(228, 228, 228);
       }
       p{
           margin:0.5rem;
           margin-left:1rem;
           margin-right:1rem;
           font-weight: 300;
    
           color:rgb(160, 160, 160);
       }
       button{
           background:var(--base-blue);
           color:rgb(235, 235, 235);
           display:block;
           margin:auto;
       }
    }

 .x-icon{
        position: absolute;
        right:1rem;
        top:1rem;
        font-size: 1.75rem;
        color:red;
        z-index:1;
        
    }

@media only screen and (max-width:700px){
    #pdf-description{
        display:none;
    }
    .render-grid{
        grid-template-columns: 100%;
    }
}
</style>