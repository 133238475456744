<template>
    <div class="card">
    <slot>

    </slot>
    <div class="card-bg">

    </div>
    </div>
</template>

<style lang="scss" scoped>
    .card{
        margin:auto;
        margin-top:1rem;
        margin-bottom:1rem;
        background:rgb(52, 63, 221);
        color:rgb(24, 24, 24);
        border-radius:5px;
        width:90%;
        height:100%;
        padding:1rem;
        position:relative;
        z-index:1;

        
    }
    .card-bg{
        position:absolute;
        margin:0;
        top:-0.5rem;
        left:-0.5rem;
        height:100%;
        width:100%;
        background-color:rgb(243, 243, 243);
        box-shadow:1px 3px 8px rgba(0, 0, 0, 0.315);
        z-index:-1;
    


    }
</style>