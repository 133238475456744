import { createStore } from "vuex";

export default createStore({
  state: {
    // URL:window.location.port=='8080' ? 'http://localhost:3000/' : '/',
    URL:'https://josiahspranklemusic.com/app/',
    uniqueURL:"-",
    types:{
      "Sheet Music Download":"music-sheet.svg",
      "MP3 Download":"mp3.svg"
    },
    loaded:false,
    downloads:[],
    downloadLinks:{
    },
    cartItems:[],
    scores:[
      // {
      //   price:7.99,
      //   duration:8,
      //   genre:"Chamber Music",
      //   instruments:["Tuba","Saxophone","Violin","Marimba"],
      //   title:"Moving Waters",
      //   visible:true,
      //   type:"Sheet Music Download",
      //   PDF:'Moving-Waters-Full-Score.pdf',
      //   category:"scores",
      //   mp3:'Moving-Waters-2017-for-Tuba-Marimba-Saxophone-and-Violin.mp3',
      //   description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur rem nisi sint, distinctio dolores reprehenderit enim ut quis aperiam quas dicta repellendus aut, nemo qui id vel assumenda rerum perferendis.",
      //   id:1,
      // },
      //    {
      //   price:10.99,
      //   duration:5,
      //   genre:"Orchestra",
      //   instruments:["Orchestra"],
      //   title:"Reminiscence",
      //   category:"scores",
      //   type:"Sheet Music Download",
      //   PDF:'Moving-Waters-Full-Score.pdf',
      //   mp3:'Moving-Waters-2017-for-Tuba-Marimba-Saxophone-and-Violin.mp3',
      //   description:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur rem nisi sint, distinctio dolores reprehenderit enim ut quis aperiam quas dicta repellendus aut, nemo qui id vel assumenda rerum perferendis.",
      //   id:2,
      //   visible:true,
      // },
    ],
    albums:[
      // {
      // title:"Psalms, Hymns & Spiritual Songs",
      // picture:"Album-Cover-PHSS.png",
      // type:"MP3 Download",
      // category:"albums",
      // description:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque mollitia molestias sed deleniti, sit, libero eum distinctio, modi voluptas minus inventore. Voluptatum sunt officia et porro. Eaque perspiciatis itaque velit?",
      // songs:[
      //         "PHSH-1-01_Come-Thou-Fount.mp3",
      //         "PHSH-2-02_It-Is-Well.mp3",
      //         "PHSH-3-03_Still.mp3",
      //         "PHSH-4-04_Let-All-Mortal-Flesh.mp3",
      //         "PHSH-5-05_Near-the-Cross.mp3",
      //         "PHSH-6-06_Mercy-Tree.mp3",
      //         "PHSH-7-07_Power-In-The-Blood-Medley.mp3",
      //         "PHSH-8-08_Here-Is-Love.mp3",
      //         "PHSH-9-09_Be-Thou-My-Vision.mp3",
      //         "PHSH-10-10_There-Is-a-Fountain.mp3",
      //         "PHSH-11-11_Praise-to-the-Lord.mp3",
      //         "PHSH-12-12_Were-You-There.mp3"
      //     ],
      //   price:7.99,
      //   id:5,
      //   },
      //   {
      //     title:"Reminiscence",
      //     picture:"Album-Cover-Final.png",
      //     description:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque mollitia molestias sed deleniti, sit, libero eum distinctio, modi voluptas minus inventore. Voluptatum sunt officia et porro. Eaque perspiciatis itaque velit?",
      //     songs:[
      //         "PHSH-1-01_Come-Thou-Fount.mp3",
      //         "PHSH-2-02_It-Is-Well.mp3",
      //         "PHSH-3-03_Still.mp3",
      //         "PHSH-4-04_Let-All-Mortal-Flesh.mp3",
      //         "PHSH-5-05_Near-the-Cross.mp3",
      //         "PHSH-6-06_Mercy-Tree.mp3",
      //         "PHSH-7-07_Power-In-The-Blood-Medley.mp3",
      //         "PHSH-8-08_Here-Is-Love.mp3",
      //         "PHSH-9-09_Be-Thou-My-Vision.mp3",
      //         "PHSH-10-10_There-Is-a-Fountain.mp3",
      //         "PHSH-11-11_Praise-to-the-Lord.mp3",
      //         "PHSH-12-12_Were-You-There.mp3"
      //     ],
      //     type:"MP3 Download",
      //     category:"albums",
      //     price:7.99,
      //     id:6,
      //   }
    ]
  },
  mutations: {
    removeItem(state,id){
      const ind = state.cartItems.findIndex(item=>item.id==id)
      state.cartItems.splice(ind,1)
      localStorage.setItem("cartItems",JSON.stringify(state.cartItems));
    },
    addItem(state,item){
      state.cartItems.push({
        image:state.types[item.type],
        item:item.title,
        description:item.type,
        price:item.price,
        id:item.id,
      })
      localStorage.setItem("cartItems",JSON.stringify(state.cartItems));
    }
  },
  getters:{
    currentPrice(state){
      let total=0
      state.cartItems.forEach(item=>{
        total+=item.price
      })
      return (Math.round(total*100))/100
    },
    quantity(state){
      return Object.keys(state.cartItems).length
    },
    addedIDs(state){
      let ids=[]
      state.cartItems.forEach(item=>{
        ids.push(item.id)
      })
      return ids
    }
  },
  actions: {},
  modules: {}
});
