import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Info from "../views/Info.vue";
import Cart from "../views/Cart.vue";
import Contact from "../views/Contact.vue";
import SheetMusic from "../views/SheetMusic.vue";
import Listen from "../views/Listen.vue";
import Checkout from "../views/Checkout.vue";
import ThankYouOrder from "../views/ThankYouOrder.vue";
import ThankYouContact from "../views/ThankYouContact.vue";
import MusicComposition from "../views/MusicComposition.vue";
import AudioEngineering from "../views/AudioEngineering.vue";
import PianoPerformance from "../views/PianoPerformance.vue";
import Oops from "../views/Oops.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/info",
    name: "Info",
    component: Info
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/sheet-music",
    name: "SheetMusic",
    component: SheetMusic
  },
  
  {
    path: "/listen",
    name: "Listen",
    component: Listen
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout
  },
  {
    path: "/piano-performance",
    name: "PianoPerformance",
    component: PianoPerformance
  },
  {
    path: "/audio-engineering",
    name: "AudioEngineering",
    component: AudioEngineering
  },
  {
    path: "/music-composition",
    name: "MusicComposition",
    component: MusicComposition
  },
  {
    path:'/thank-you-order',
    name:'ThankYouOrder',
    component:ThankYouOrder
  },
  {
    path:'/thank-you-contact',
    name:'ThankYouContact',
    component:ThankYouContact
  },
  {
    path:'/oops',
    name:'Oops',
    component:Oops
  },
  {
    path:'/:pathMatch(.*)*',
    component:Oops
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to) {
    if(!to.hash) {
      return document.querySelector('#top-of-page').scrollIntoView({ behavior: 'smooth' });
    }
    else if (to.hash) {
      return document.querySelector(to.hash).scrollIntoView({ behavior: 'smooth' });
    } 

  },
  routes
});

export default router;
