<template>
  <div class="contact">
    <white-card class="gradient">
      
      <h2 class="heading-primary" style="font-weight:300;">Contact</h2>
      <p style="text-align:center;font-size:1.2rem;">Want to get in touch? Leave me a message below!</p>
      <dark-card id='form-bgd' style="max-width:500px;">
        <i v-if="sending" id="spinner-icon" class="fas fa-spinner"></i>
        <div class="giant-ball"> </div>
        <form :class="{halfOpacity:sending}" @submit.prevent="submitForm">
          <div class="form-control">
              <label style="font-weight:600;" for="name">Name<span style="color:red;">*</span></label>
              <br>
              <input
              @focus='typing("name")'
              @blur='checkInput("name")'
              required class="big-input" placeholder="Name" ref='name' id="name" name="name" type="text">  
          </div>
          <br>
          <div class="form-control">
              <label style="font-weight:600;" for="email">Email<span style="color:red;">*</span></label>
              <br>
              <input
              @focus='typing("email")'
              @blur='checkInput("email")'
               required class="big-input" placeholder="Email" id="email" name="email" type="email">  
          </div>
          <br>
          <div style="font-weight:600;" class="form-control">I'm Interested In:</div>

          <div id="checkboxes" class="form-control">
              
              <input type="checkbox" name="interestedin" value="composition" id="composition">&nbsp;
              <label for="composition">New Music</label>
              <br>
              
              <input type="checkbox" name="interestedin" value="recording" id="recording">&nbsp;
              <label for="recording">Recording</label>
              <br>

              <input type="checkbox" name="interestedin" value="mixing" id="mixing">&nbsp;
              <label for="mixing">Mixing</label>
              <br>

              <input type="checkbox" name="interestedin" value="piano" id="piano">&nbsp;
              <label for="piano">Piano Performance</label>
              <br>
              <input type="checkbox" name="interestedin" value="other" id="other">&nbsp;
              <label for="other">Other</label>
              <br>
          </div>
          <br>
          <div class="form-control">
              <label style="font-weight:600;" for="title">Title<span style="color:red;">*</span></label>
              <br>
              <input
                @focus='typing("title")'
              @blur='checkInput("title")'
               required class="big-input" placeholder="What's your topic?" id="title" title="title" type="text">  
          </div>
          <br>
          <div class="form-control">
              <label style="font-weight:600;" for="message">Message<span style="color:red;">*</span></label>
              <br>
              <textarea
                @focus='typing("message")'
              @blur='checkInput("message")'
               required class="big-input" placeholder="How can I help?" name="message" id="message" cols="30" rows="4"></textarea>
          </div>
          <button class="submit-button">Submit</button>
          

        </form>
      </dark-card>
    </white-card>
    
  </div>
</template>

<script>
import WhiteCard from '../components/ui/WhiteCard'
import DarkCard from '../components/ui/DarkCard'
export default {
  components:{
    WhiteCard,
    DarkCard
  },
  data(){
    return{
      dir:this.$store.state.URL+'media/',
      sending:false
    }
  },
  methods:{
        typing(id){
          let element = document.getElementById(id)
          let clist = element.classList
          clist.remove('invalid')
          clist.remove('valid')
          clist.add('typing')
        },
        checkInput(id){
          let element = document.getElementById(id)
          let clist = element.classList
          let emailTrue = true
          if(id=='email'){
            var re = /\S+@\S+\.\S+/;
            emailTrue = re.test(element.value);
          }
          if(!element.value|!emailTrue){
            clist.remove('typing')
            clist.add('invalid')
          }else{
            clist.remove('invalid')
            clist.add('valid')
          }
          
        },
    async submitForm(){
        let inputValues=[]
        document.querySelectorAll('input').forEach(input=>{
          if(input.checked){
            inputValues.push(input.value)
          } 
        })

        let obj={
          name:document.getElementById("name").value,
          email:document.getElementById("email").value,
          interestedIn:inputValues,
          title:document.getElementById("title").value,
          message:document.getElementById("message").value,
        }

        this.sending=true
        try {
          let timeout = setTimeout(()=>{
            throw new Error('Timeout')
          },30000)
          const response = await fetch(this.$store.state.URL+'contact',{
          method:"POST",
          body:JSON.stringify(obj),
          headers:{
            "Content-Type":"application/json",
            "Accept":"application/json"
             }
          })
          if(response.ok){
            console.log(response)
            this.sending=false
            clearTimeout(timeout)
            this.$router.push('/thank-you-contact')
          }else{
            console.log(response)
            this.$router.push('/oops')
          }
          
        } catch (error) {
          console.log(error.message)
          this.sending=false
          this.$router.push('/oops')
        }

      
    }
  }
}
</script>

<style lang="scss" scoped>
.contact{
  margin-top:-2rem;
  width:100%;

  .form-control{
    font-size:1.2rem;
    display:block;
  }

  textarea{
    font-family: 'Roboto', sans-serif !important;
  }
  #checkboxes{
    font-weight:200;
    color:rgb(172, 172, 172);
    border:1px solid rgba(255, 255, 255, 0.336);
    padding:0.5rem;
    border-radius:5px;
    margin-top:0.1rem;
  }
  .big-input{
    width:100%;
    font-size:1rem;
    padding:0.5rem;
    outline:transparent;
    box-shadow:none;
    border:none;
    background:white;
    border-radius:0.2rem;

  }
  

  ::placeholder{
    font-size:1rem !important;
    font-family: 'Roboto', sans-serif;
  }
  

}


.unchecked{
  background:white;
}
.typing{
  background:rgb(160, 196, 230) !important;
}
.valid{
  background:rgb(168, 230, 160) !important;
}
.invalid{
  background:rgb(230, 160, 160) !important;
}

#form-bgd{
  background:rgb(32, 32, 32);
}

#spinner-icon{
  font-size:5rem;
  transform:translate(-50%, -50%);
  top:40%;
  left:50%;
  position:absolute;
  animation:spin 2s infinite;

}
@keyframes spin{
  0%{
    transform:translate(-50%, -50%) rotateZ(0deg) scale(1);
  }
  50%{
transform:translate(-50%, -50%) rotateZ(180deg) scale(1.6);
  }
   100%{
    transform:translate(-50%, -50%) rotateZ(359deg) scale(1);
  }
}
.halfOpacity{
  opacity:0.5;
}


</style>