<template>
    <dark-card style="width:calc(92%);margin-top:3rem;" class="large-padding">
          <h2 class="heading-primary" style="font-weight:300;">Portfolio</h2>
            <h3 style="font-weight: 300;text-align:center;" class="heading-secondary">Listen on YouTube, or check out my Soundcloud below.</h3>
            <a style="text-decoration:none;" target="_blank" href="https://www.youtube.com/user/Kopaka0111">
            <button class="btn center">
            Listen on YouTube.
            </button>
            </a>
    </dark-card>
</template>

<script>
import DarkCard from "./DarkCard.vue"
export default {
    components:{DarkCard}
}
</script>

<style scoped>
    button{
        margin-top:1rem;
        font-size:1.25rem;
        padding:1rem;
        background: rgb(226, 226, 226);
        color:black;
    }

    .large-padding{
        padding: 2rem;
    }
</style>