<template>
    <button class="button">
        <slot></slot>
    </button>
</template>

<style lang="scss" scoped>
.button{
    
    color:rgb(41, 41, 41);
    background:linear-gradient(rgb(241, 241, 241),rgb(190, 190, 190));
    border:none;
    padding:1rem 2rem 1rem 2rem;
    text-transform: uppercase;
    border-radius:0.5rem;
    font-size:0.9rem;
    font-weight:600;
    transition:0.25s ease-out;
    outline:none;
    box-shadow:1px 3px 5px rgba(0, 0, 0, 0.466);
    cursor:pointer;

    &:hover{
        opacity:0.6;
        transform:translate(15px, 0px)
    }
    &:active{
        opacity:0.8;
        transform:translate(10px, 0px)
    }

}



</style>